import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate ,Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'

import { motion } from 'framer-motion';

// import AddCampaignform from './AddCampaignform';


const Makegui = (props) => {

    useEffect(() => {
        document.title = "Dialer Crm"
     }, []);

    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

   




    const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const refClose = useRef(null)
    const [ecampaignid, setEcampaignid] = useState()
    const [ecampaign, setEcampaign] = useState()
    const [ecampaignmode, setEcampaignmode] = useState()
    const [estarttime, setestarttime] = useState()
    const [eendtime, setEendtime] = useState()
    const [eselectaudio, setEeselectaudio] = useState()
    const [eselectlist,setEeselectlist] = useState()

    const [esubmitbutton, setesubmitbutton] = useState()

   


   
    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllCamp?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");
    const [audio, setAudio] = useState();
    const[listname,setList] = useState();







    const getPostData = (data) => {
        return (
            <>

             <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}

               >

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 '>

                        <div className='card p-2 mt-2'>



                            <div className=' text-black'>
                                <h4 className='text-center card-header'>Existing Campaign</h4>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered checkoddata">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>Campaign Name</th>
                                               
                                                <th>CRM Field</th>
                                                <th>View CRM</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.campname}</td>
                                                       
                                                        {/* <td>
                                                            <button className=" mx-2 btn btn-primary" onClick={() => updateCampaign()}>
                                                                <i className="far fa-edit" ></i>
                                                            </button>

                                                        </td> */}

                                                        <td> <Link to="/Editgui" className='btn btn-primary btn-mysm' state={ {id: page.id,name: page.campname}}>
                                                            <i className="fa far fa-edit " aria-hidden="true" ></i>
                                                          
                                                            </Link></td>
                                                        

                                                        <td> <Link  className='btn btn-primary btn-mysm' to="/Editgui" state={ {id: page.id,name: page.campname}}>
                                                            <i className="fa fa-eye " aria-hidden="true" ></i>
                                                          
                                                            </Link></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                </motion.div>
            </>

        )

    }


    return (
        <>

    




        <div className='row mt-2 mybgcolor2 p-2'>


           


            <div className="col-md-12 col-12 ignoreline">

                {/* Display all the posts */}
                {posts}

                <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>
        </>
    )


}


export default Makegui
