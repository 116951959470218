import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { motion } from 'framer-motion';
import { BsFillUnlockFill } from "react-icons/bs";

import { BsArrowRightSquare } from 'react-icons/bs';
import { FcEmptyTrash } from 'react-icons/fc';
import { AiOutlineLogout } from "react-icons/ai";
import ReactPaginate from 'react-paginate';

const AddLeadStatus = () => {

    useEffect(() => {
        document.title = "AddLeadStatus"
     }, []);

    const addleadref = useRef(null)
    const updateRemarksref = useRef(null)
    

    const addLeadModel = () => {

        //alert(e)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

       // setUsername('')
       // setPassword('')

       addleadref.current.click();


    }

   

    

    
    

    const [lstatus, setLstatus] = useState("NA")

    const [leadname, setLeadname] = useState("NA")
    const [leadnumber, setLeadnumber] = useState("NA")
    const [leadaltnumber, setLeadaltnumber] = useState("NA")
    const [leademail, setLeademail] = useState("NA")
    const [leadstate, setLeadstate] = useState("NA")
    const [leaddis, setLeaddis] = useState("NA")
    const [leadpin, setLeadpin] = useState("NA")
    const [leadadr, setLeadadr] = useState("NA")
    const [leadrem, setLeadrem] = useState("NA")
    const [leadoth, setLeadoth] = useState("NA")


    const [leadid, setLeadid] = useState()
    const updateRemarks = (e1,e2) => {

        //alert(e)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

       // setUsername('')
       // setPassword('')
       setLeadid(e1);
       setLeadrem(e2);

       updateRemarksref.current.click();


    }


    const addLeadApiCall = async(e) => {

          // alert(lstatus)
          
          e.preventDefault();
        var formdata = new FormData()
        formdata.append('leadstatus', lstatus)
       

          const response = await fetch(process.env.REACT_APP_IP + "/User/AddLeadStatus", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()
        
       setLstatus("NA")
      

       addleadref.current.click();


    }




    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllLeadstatus?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })      
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])



    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card p-2 mybgcolor' >



                            <div className=' text-black '>
                                


                                <div className="table-responsive p-1">
                                    <motion.div
                                        initial={{ rotate: 270 }}
                                        animate={{ x: 0, opacity: 2.0, rotate: 360 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <table className="table table-hover table-bordered checkoddata ignoreline">
                                            <thead className="bg-darkblue text-white nowrap">
                                                <tr>
                                                    <th>ID</th>
                                                   
                                                    <th>LeadStatus</th>

                                                                                             
                                                    <th>Date</th>
                                                    <th>LastActionDate</th>
                                                    <th>Delete</th>
                                                   
                                                   


                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.content.map(page => {
                                                    return (
                                                        <tr key={page.id}>
                                                            <td>{page.id}</td>
                                                            <td>{page.leadstatus}</td>
                                                          
                                                           
                                                           
                                                            <td>{page.insertdate}</td>
                                                            <td>{page.modifieddate}</td>
                                                            <td>
                                                                <button className=" btn btn-danger btn-mysm "
                                                                 //onClick={() => logoutAgent(page.id)}
                                                                 
                                                                 >
                                                                    <i className="fa fa-trash" ></i>
                                                                </button>
                                                            </td>

                                                          



                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} />
                                    </motion.div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }





  return (
    <>

                
                       
       {/* Update Remarks Lead Model */}

                        
       <button ref={updateRemarksref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#updateremarkModal">
                    Add User
                </button>
                <div className="modal fade" id="updateremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="updateremarkModal">Update Remarks</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">


                                <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                    minHeight: "300px"

                                }} 
                                onSubmit={addLeadApiCall}
                                 >
                                    <div className='card-header text-black mb-2'>
                                        <h4>Update Remarks</h4>
                                    </div>
                                    <div className=" row">

                                        
                                        <div className="col-md-12 col-12">
                                        <label>LeadId</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Remarks"
                                                name="username"
                                                value={leadid}
                                                //onChange={(e) => setLeadrem(e.target.value)} 
                                                readOnly />

                                        </div>

                                        </div>

                                        </div>
                                    
                                    <div className=" row">


                                            <div className="col-md-12 col-12">
                                            <label>Name</label>
                                            <div className=" mb-2 ">

                                                <input type="text" className="form-control" id="username" placeholder="Remarks"
                                                    name="username"
                                                     value={leadrem}
                                                    onChange={(e) => setLeadrem(e.target.value)} 
                                                    required />

                                            </div>

                                            </div>
                                   
                                    </div>
                                    
                                   
                                   

                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </form>

                            </div>
                            
                        </div>
                    </div>
                </div>



                        {/* Add Lead Status Model */}

                        
                <button ref={addleadref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#addleadModal">
                         Add Lead Status
                </button>
                <div className="modal fade" id="addleadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addleadModal">Add Lead Status</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">


                                <form className='card p-1 mt-1 mybgcolor mx-1'
                                //  style={{
                                //     minHeight: "100px"

                                // }} 

                                onSubmit={addLeadApiCall}
                                 >
                                    <div className='card-header text-black mb-2 text-center'>
                                        <h4>Add Lead Status</h4>
                                    </div>

                                    <div className=" row mt-2 mb-2">
                                            <div className="col-md-12 col-12">
                                                <label>Status</label>
                                                <div className=" mb-2 ">

                                                    <input type="text" className="form-control" id="username" placeholder="Enter status" title="NA For Not Available"
                                                        name="username"
                                                         value={lstatus}
                                                        onChange={(e) => setLstatus(e.target.value)} 
                                                        required />

                                                </div>

                                            </div>
                                   
                                    </div>

                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </form>

                            </div>
                            
                        </div>
                    </div>
                </div>




                <div className='row'>
          <div className='col-md-12'>
 
            <div className="">
              {/* <div className='card'>
                  <div className='card-header'>
                  
                    </div>

              </div> */}
              <h1 className='text-center card-header'>Existing Lead Status</h1>
              <div className='card mybgcolor' >
                                 <div className='p-2 ms-1'>
                                    <div className='row'>
                                    <div className='col-md-4 col-4 text-start'>
                                        <button className='btn btn-primary btn-mysm '
                                                 onClick={() => addLeadModel()}
                                                 >Add Lead Status</button>
                                        </div>
                                        <div className='col-md-4 col-4'>
                                       
                                        </div>
                                        <div className='col-md-4 text-end'>
                                           
                                        </div>
                                    </div>


                                </div>
                        </div>

              {/* Display all the posts */}
              {posts}

              {/* Using React Paginate */}
             

            </div>
            
            <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                     nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
                     previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}
                     
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}
                
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination2"}
                    activeClassName={"active"} />

              </div>
              <div className='col-md-4'></div>
            </div>


          </div>


        </div>

    
    </>
    
  )
}

export default AddLeadStatus