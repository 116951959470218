import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';


import axios from 'axios'

// import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from './AudioPlayer';
import { AnimatePresence, motion } from "framer-motion";

// import Userivrnavbar from './Userivrnavbar';
import { CSVLink, CSVDownload } from "react-csv";


const Reportlive = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "Live CallReport"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };

  


  

 
  
  //const data = reportdaat;

  //downloadcsv
  const downloadcsv = async () => {
    const res = await axios.get(process.env.REACT_APP_IP + `/User/DpwnloadSampleUpload/CampaignReport.csv`,
      { 
        headers: 
        { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem('token')}` 
        } 
      }
      )

  }

  const openInNewTab = url => {

    var url=process.env.REACT_APP_IP + `/User/DpwnloadSampleUpload/CampaignReport.csv`
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  

 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
      
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{page.id}</td>
                      {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                <th>{page.userid}</th>:""}
                      <td>{page.fone}</td>
                      {/* <td>{page.did}</td> */}
                        <td>{page.campaignid}  |  {page.campaign} </td>
                      <td>{page.type}</td>
                      <td>{page.agentid} | {page.agentsip}</td>
                      <td >{page.dialtime}</td>
                      <td>{page.customerhanguptime}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.dispoone}</td>
                      <td>{page.pressedkey}</td>
                      <td>{page.dialstatus}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                      {/* <td><i className="fa fa-phone"  ></i></td>  */}
                      {/* <td>
                      <button className=" mx-2 btn btn-success" >
                                                                <i className="fa fa-phone" ></i>
                                                            </button>
                                                            </td> */}
                      
                      {/* <td className='bg-secondary'> */}
                      <td>
                          {/* <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname}
                              controls /> */}

                             <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.userid+'/'+page.fold+'/'+page.recname} />
                      </td>

                  
                      {/* <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td> */}


                    </tr>
                  );
                })}
              </tbody>
          
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReportForLive`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  // useEffect(() => {
  //   getAllPosts();
  // }, [offset])

  useEffect(() => {
    try{
      if (localStorage.getItem('token')) {
        getAllPosts();
      const timer = setInterval(async () => {
        if (localStorage.getItem('token')) {
        getAllPosts()
        }


      return () => clearInterval(timer);
    }, 5000)
       
      } else {
        
      }
      
    }catch(error)
    {
      console.log("Error")
    }
   
  }, [offset])

  return (
     <>  
     <div className='ms-1 me-3 mt-1 mb-3'>
        <div className='row '>
          <div className='col-md-12'>           
              <div className='text-center card-header'> LiveCall Report </div>  
              <table className="table table-hover table-bordered checkoddata ignoreline ">
              <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>
                  {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                <th>UserId</th>:""}
                  <th>Number</th>
                  <th>Campaign</th>
                  <th>Calltype</th>
                  <th>Agents</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>Dispo</th>
                  <th>Keypress</th>
                  <th>Status</th>
                  {/* <th>Action</th> */}
                  <th>Recording</th>
                 
                 
                  

                </tr>
              </thead>
               {posts}
              
            </table>          
              
                         
          </div> 
        </div>
        </div>

      </>
    
  )
}

export default Reportlive
