import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import axios from 'axios'
import { BsFillUnlockFill } from "react-icons/bs";

import { BsArrowRightSquare } from 'react-icons/bs';
import { FcEmptyTrash } from 'react-icons/fc';
import { AiOutlineLogout } from "react-icons/ai";


import { motion } from 'framer-motion';

// import AddCampaignform from './AddCampaignform';


const Users = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })



    // const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const pwdref = useRef(null)
    const adduserref = useRef(null)




    const [euserprofileid, setEprofileid] = useState()
    const [ename, setEname] = useState("")
    const [eaddress, setEeaddress] = useState("NA")
    const [emobile, setEemobile] = useState("NA")
    const [esip, setEesip] = useState("NA")
    const [edid, setEedid] = useState("NA")
    const [ecampaign, setEecampaign] = useState("NA")
    const [ebackp, setEebackp] = useState("NA")
    const [evalidity, setEevalidity] = useState("NA")
    const [eobdpulse, setEeobdpulse] = useState("NA")
    const [evoicecredit, setEevoicecredit] = useState("NA")
    const [evoicecreditconsume, setEevoicecreditconsume] = useState("NA")


    const [chagePwdid, setChagePwdid] = useState()
    const [chagePwdusername, setChagePwdusername] = useState()
    const [pwd, setPwd] = useState()

    const [confpwd, setConfpwd] = useState()




    const updatePwd = (e1, e2) => {

        //alert(e)
        setChagePwdid(e1);
        setChagePwdusername(e2);

        pwdref.current.click();


    }

    useEffect(() => {
        document.title = "Add Users"
    }, []);


    const addUserModel = () => {

        //alert(e)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        setUsername('')
        setPassword('')

        adduserref.current.click();


    }

    const [campaigns, setCampaigns] = useState('NA');

    const searchCampaign =  (e) => {
      //  alert(campaigns)
    e.preventDefault()
    //setCampaigns(e)
     //alert(">>>>>>")
    // setCampaigns()
     getAllPosts()

    }

    const logoutALlAgent= async (e) => {
        // e.preventDefault();
        // var formdata = new FormData()
        // formdata.append('id', e)
         const response = await fetch(process.env.REACT_APP_IP+"/Admin/LogoutAllAgentApi", {
             method: 'POST',
             // headers: {
             //     'Content-Type': 'application/json'
             // },
 
             headers: {
                 //'Content-Type': 'application/json',
 
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
             }
            // body: formdata
             // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
         });
         const json = await response.json()
         console.log(json);
         if(json.type==="OK")
         {
            // props.showAlert(json.msg, "success");
            Swal.fire({
             position: 'top-end',
             icon: 'success',
             title: json.msg,
             background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
             showConfirmButton: false,
             timer: 2500
           })
         }else{
             //props.showAlert(json.msg, "danger");
             Swal.fire({
                 position: 'top-end',
                 icon: 'error',
                 title: json.msg,
                 background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                 showConfirmButton: false,
                 timer: 2500
               })
         }
        
         //history('/AddCampaign');
         // alert("Campaign Added");
        // setformyalert(!formyalert);
        // getAllPosts()
 
     }

    const deleteUser = async (e1, e2) => {
        // alert(e1 +">>>>>>"+e2)
        // console.log("id>>>>>>>>" + e) 
        var formdata = new FormData()
        formdata.append('id', e1)
        formdata.append('username', e2)

        const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteAgent", {
            method: 'POST',
            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }


        // setGroupname("NA")
        //  set

        getAllPosts()

    }





    const changepwdfun = async (e) => {

        e.preventDefault();
        var formdata = new FormData()
        formdata.append('pwd', pwd)
        formdata.append('id', chagePwdid)

        if (pwd === confpwd) {
            //alert("matched")
            const response = await fetch(process.env.REACT_APP_IP + "/Admin/Passwordchangebyadmin", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    // 'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: json.msg,
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: json.msg,
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    showConfirmButton: false,
                    timer: 2500
                })
            }
            setPwd('')
            setConfpwd('')
            pwdref.current.click();
            getAllPosts()


        } else {
            alert("pwd not matched")
            return
        }

    }




    const onConnected = async (e) => {
        //e.preventDefault();
        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/User/onConnected", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {


                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()



    }


    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch(process.env.REACT_APP_IP + "/Admin/getUserById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEprofileid('')
        setEname('')
        setEeaddress('')

        setEemobile('')
        setEesip('')
        setEedid('')
        setEecampaign('')
        setEebackp('')
        setEevalidity('')
        setEeobdpulse('')
        setEevoicecredit('')
        setEevoicecreditconsume('')
        setEprofileid(json.id)

        setEname(json.name)

        setEeaddress(json.address)

        setEemobile(json.mobile)
        setEesip(json.sip)
        setEedid(json.did)
        setEecampaign(json.campaign)
        setEebackp(json.backp)
        setEevalidity(json.validity)
        setEeobdpulse(json.obdpulse)
        setEevoicecredit(json.voicecredit)
        setEevoicecreditconsume(json.voicecreditconsume)

    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }

    const logoutAgent = async (e) => {
        // e.preventDefault();
        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/Admin/LogoutAgentApi", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()

    }


    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(euserprofileid + ">>>>>>>" + ename + ">>>>>>>>>" + ecampaign + ">>>>>>>>>" + eaddress
            + ">>>>>>>>" + emobile + ">>>>>>>>>>>" + esip + ">>>>>>>>>>>" + edid + ">>>>>>>>>>>" + ebackp
            + ">>>>>>>>>>>" + evalidity + ">>>>>>>>>>>" + eobdpulse + ">>>>>>>>>>>" + evoicecredit)

        var formdata = new FormData()
        formdata.append('id', euserprofileid)
        formdata.append('name', ename)
        formdata.append('campaign', ecampaign)
        formdata.append('address', eaddress)
        formdata.append('mobile', emobile)
        formdata.append('sip', esip)
        formdata.append('did', edid)
        formdata.append('backp', ebackp)
        formdata.append('validity', evalidity)
        formdata.append('obdpulse', eobdpulse)
        formdata.append('voicecredit', evoicecredit)
        formdata.append('voicecreditconsume', evoicecreditconsume)





        const response = await fetch(process.env.REACT_APP_IP + "/Admin/EditUserProfile", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        console.log(response)

        ref.current.click();
        getAllPosts()

    }





    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {

        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/RestAllUser?username=${campaigns}&&pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        
        
       
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [roles, setRoles] = useState("");




    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addUserSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch(process.env.REACT_APP_IP + "/Admin/AddUser", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "username": username,
                "password": password,
                "roles": roles

            })
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        // setEprofileid('')
        // setEname('')
        // setEeaddress('')

        // setEemobile('')
        // setEesip('')
        // setEedid('')
        // setEecampaign('')
        // setEebackp('')
        // setEevalidity('')
        // setEeobdpulse('')
        // setEevoicecredit('')
        // setEevoicecreditconsume('')

        adduserref.current.click();

    }




    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-4'>

                        <div className='card p-2 mybgcolor mt-2' >



                            <div className=' text-black '>
                                <div className='card-header'>
                                    <div className='row'>
                                       <div className='col-md-4 text-start mt-2'>
                                        <button className='btn btn-primary btn-mysm '
                                                 onClick={() => addUserModel()}
                                                 >AddUser</button>
                                        </div>
                                        <div className='col-md-4'>
                                            <h4 className='text-center'>Existing User</h4>
                                        </div>
                                        <div className='col-md-4 text-end mt-2'>
                                            <button className='btn btn-danger btn-mysm '
                                                onClick={() => logoutALlAgent()}><AiOutlineLogout className='me-2' />All Logout</button>
                                        </div>
                                    </div>


                                </div>


                                <div className="table-responsive p-1">
                                    <motion.div
                                        initial={{ rotate: 270 }}
                                        animate={{ x: 0, opacity: 2.0, rotate: 360 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <table className="table table-hover table-bordered checkoddata ignoreline">
                                            <thead className="bg-darkblue text-white nowrap">
                                                <tr>
                                                    <th>ID</th>

                                                    <th>User Name</th>
                                                    {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                        <th>PWD</th> : ""}
                                                    {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                        <th>UserId</th> : ""}
                                                    {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                        <th>AllowAgent</th> : ""}
                                                    <th>User Type</th>
                                                    <th>Name</th>
                                                    <th>SIP</th>
                                                    <th>SIPCONNECTED</th>
                                                    <th>Status</th>
                                                    <th>LoginStatus</th>
                                                    {/* <th>Total Credit</th>
                                                <th>Credit Remain</th> */}
                                                    <th>Logout</th>
                                                    <th>Pwd</th>
                                                    <th>Delete</th>
                                                    <th>Edit</th>
                                                    {/* <th>Delete</th> */}


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.content.map(page => {
                                                    return (
                                                        <tr key={page.id}>
                                                            <td>{page.id}</td>
                                                            <td>{page.username}</td>
                                                            {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                                <th>{page.userpro.pwdbackup}</th> : ""}
                                                            {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                                <th>{page.userpro.userid}</th> : ""}
                                                            {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                                <th>{page.userpro.validity}</th> : ""}
                                                            <td>{page.roles.substring(5)}</td>
                                                            <td>{page.userpro.name}</td>
                                                            <td>{page.userpro.sip}</td>
                                                            <td>
                                                                <span className='row ms-2'>
                                                                    {page.userpro.vc} |

                                                                    <label className="ms-1 toggle-switch">
                                                                        <input type="checkbox" checked={page.userpro.vc === "YES"}
                                                                            onChange={() => onConnected(page.id)}

                                                                        />
                                                                        <span className="switch" />
                                                                    </label>
                                                                </span>
                                                            </td>

                                                            <td>{page.userpro.status}</td>
                                                            <td>

                                                                {page.userpro.loginstatus}


                                                            </td>

                                                            <td>
                                                                <button className=" btn btn-danger btn-mysm" onClick={() => logoutAgent(page.id)}>
                                                                    <i className="fa fa-sign-out " ></i>
                                                                </button>
                                                            </td>

                                                            {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                                <>

                                                                    <td>
                                                                        < BsFillUnlockFill size="25px" color="blue" style={{ cursor: "pointer" }}

                                                                            onClick={() => updatePwd(page.id, page.username)}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        < FcEmptyTrash size="25px" color="blue" style={{ cursor: "pointer" }}

                                                                            onClick={() => deleteUser(page.id, page.username)}

                                                                        />
                                                                    </td>
                                                                </>

                                                                : ''


                                                            }

                                                            {localStorage.getItem('roles') === 'ROLE_ADMIN' ?

                                                                //    <button className="btn btn-primary btn-sm FcUnlock" onClick={() => updateCampaign(page.userpro.id)}>
                                                                //     </button>
                                                                <>
                                                                    <td className='text-center'>
                                                                        < BsFillUnlockFill size="25px" color="blue" style={{ cursor: "pointer" }}

                                                                            onClick={() => updatePwd(page.id, page.username)}

                                                                        />
                                                                    </td>

                                                                    <td className='text-center'>
                                                                        < FcEmptyTrash size="25px" color="blue" style={{ cursor: "pointer" }}

                                                                            onClick={() => deleteUser(page.id, page.username)}

                                                                        />
                                                                    </td>
                                                                </>

                                                                : ''


                                                            }

                                                            <td className='text-center'>
                                                                <button className=" btn btn-primary btn-mysm" onClick={() => updateCampaign(page.userpro.id)}>
                                                                    <i className="fa fa-edit" ></i>
                                                                </button>

                                                            </td>
                                                            {/* <td> <i className="far fa-trash-alt mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}></i></td> */}




                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} />
                                    </motion.div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (

        <>




            <div className='row topmargin'>


                {/* Add User Model */}


                <button ref={adduserref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#addusrModal">
                    Add User
                </button>
                <div className="modal fade" id="addusrModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addusrModal">Change Pwd</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">


                                <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                    minHeight: "300px"

                                }} onSubmit={addUserSubmit} >
                                    <div className='card-header text-black mb-2'>
                                        <h4>Add User</h4>
                                    </div>

                                    <label>User Name</label>
                                    <div className=" mb-2 ">

                                        <input type="text" className="form-control" id="username" placeholder="Enter username"
                                            name="username" value={username}
                                            onChange={(e) => setUsername(e.target.value)} required />

                                    </div>

                                    <label>Password</label>
                                    <div className="mb-2 ">
                                        <input type="text" className="form-control" id="password" placeholder="Enter Password"
                                            name="password" value={password}
                                            onChange={(e) => setPassword(e.target.value)} required />

                                    </div>

                                    {/* <div className="form-floating mb-3 mt-1">
            <input type="text" className="form-control" id="campaign_mode" placeholder="Enter campaign_mode"
             name="campaign_mode"  value={campaign_mode} 
             onChange={(e) => setcampaign_mode(e.target.value)} />
            <label htmlFor="campaign_mode">Campaign Mode</label>
        </div> */}

                                    <label>User Type:</label>
                                    <div className=" mb-2">
                                        <select className="form-select" name="roles" value={roles}
                                            onChange={(e) => setRoles(e.target.value)} >
                                            <option value="">Select User Type</option>
                                            {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                <option value="ROLE_ADMIN">ADMIN</option>

                                                : <option value="ROLE_USER">Agent</option>
                                            }


                                        </select>

                                    </div>






                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </form>

                            </div>
                            <div className="modal-footer">
                                {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                                {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                            </div>
                        </div>
                    </div>
                </div>




                {/* Change Pwd Model */}


                <button ref={pwdref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#changepwdModal">
                    Change Pwd
                </button>
                <div className="modal fade" id="changepwdModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="changepwdModal">Change Pwd</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">


                                <form className="" onSubmit={changepwdfun}>
                                    <div className='row mt-2 mb-2'>
                                        <div className='col-md-6'>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Id</label>
                                                <input type="text" className="form-control" value={chagePwdid}
                                                    onChange={(e) => setChagePwdid(e.target.value)} required={true} readOnly={true} />
                                            </div>




                                        </div>

                                        <div className='col-md-6'>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Username</label>
                                                <input type="text" className="form-control" value={chagePwdusername}
                                                    onChange={(e) => setChagePwdusername(e.target.value)} required={true} readOnly={true} />
                                            </div>




                                        </div>


                                        <div className="col-md-6">
                                            <label>Enter New Password:</label>
                                            <input className='form-control' type="password"
                                                onChange={(e) => setPwd(e.target.value)} name="pwd"
                                                value={pwd} id="pwd"
                                                required />


                                        </div>
                                        <div className="col-md-6 ">

                                            <label className=''>Confirm Password:</label>
                                            <input className='form-control' type="password" name="confpwd"
                                                value={confpwd} onChange={(e) => setConfpwd(e.target.value)}
                                                required />


                                        </div>



                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer">
                                {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                                {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                            </div>
                        </div>
                    </div>
                </div>



                <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit/View User Profile</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form className=" p-2" onSubmit={submiteditform}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Id</label>
                                                <input type="text" className="form-control" id="euserprofileid" name="euserprofileid" value={euserprofileid}
                                                    onChange={(e) => setEprofileid(e.target.value)} required={true} readOnly={true} />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="ename" name="ename" value={ename}
                                                    onChange={(e) => setEname(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Address</label>
                                                <input type="text" className="form-control" id="eaddress" name="eaddress" value={eaddress}
                                                    onChange={(e) => setEeaddress(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Mobile</label>
                                                <input type="text" className="form-control" id="emobile" name="emobile" value={emobile}
                                                    onChange={(e) => setEemobile(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Sip</label>
                                                <input type="text" className="form-control" id="esip" name="esip" value={esip}
                                                    onChange={(e) => setEesip(e.target.value)} required={true} />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Did</label>
                                                <input type="text" className="form-control" id="edid" name="ename" value={edid}
                                                    onChange={(e) => setEedid(e.target.value)} required />
                                            </div>

                                        </div>
                                        <div className='col-md-6'>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Campaign</label>
                                                <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                                    onChange={(e) => setEecampaign(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Backup</label>
                                                <input type="text" className="form-control" id="ecampaign" name="ebackp" value={ebackp}
                                                    onChange={(e) => setEebackp(e.target.value)} required />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Validity</label>
                                                <input type="text" className="form-control" id="evalidity" name="evalidity" value={evalidity}
                                                    onChange={(e) => setEevalidity(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">OBD Pulse</label>
                                                <input type="text" className="form-control" id="eobdpulse" name="eobdpulse" value={eobdpulse}
                                                    onChange={(e) => setEeobdpulse(e.target.value)} required />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Credit</label>
                                                <input type="text" className="form-control" id="evoicecredit" name="evoicecredit" value={evoicecredit}
                                                    onChange={(e) => setEevoicecredit(e.target.value)} required />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Credit Consume</label>
                                                <input type="text" className="form-control" id="evoicecreditconsume" name="evoicecreditconsume" value={evoicecreditconsume}
                                                    onChange={(e) => setEevoicecreditconsume(e.target.value)} required />
                                            </div>


                                        </div>

                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer">
                                {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                                {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}


                {/* <div className='col-md-3 col-12 mt-4 '>
                <form className='card p-1 mt-1 mybgcolor mx-1' style={{ minHeight: "300px"

                }} onSubmit={addUserSubmit} >
                    <div className='card-header text-black mb-2'>
                        <h4>Add User</h4>
                    </div>

                    <label>User Name</label>
                    <div className=" mb-2 ">
                         
                        <input type="text" className="form-control" id="username" placeholder="Enter username"
                            name="username" value={username}
                            onChange={(e) => setUsername(e.target.value)} required />
                        
                    </div>

                    <label>Password</label>
                    <div className="mb-2 ">
                        <input type="text" className="form-control" id="password" placeholder="Enter Password"
                            name="password" value={password}
                            onChange={(e) => setPassword(e.target.value)} required />
                        
                    </div> */}

                {/* <div className="form-floating mb-3 mt-1">
                            <input type="text" className="form-control" id="campaign_mode" placeholder="Enter campaign_mode"
                             name="campaign_mode"  value={campaign_mode} 
                             onChange={(e) => setcampaign_mode(e.target.value)} />
                            <label htmlFor="campaign_mode">Campaign Mode</label>
                        </div> */}

                {/* <label>User Type:</label>
                    <div className=" mb-2">
                        <select className="form-select" name="roles" value={roles}
                            onChange={(e) => setRoles(e.target.value)} >
                            <option value="">Select User Type</option>
                            {localStorage.getItem('roles')==='ROLE_SUPERADMIN'?
                            <option value="ROLE_ADMIN">ADMIN</option>

                            : <option value="ROLE_USER">Agent</option>
                           }
                           
                           
                        </select>
                        
                    </div>       
                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                </form>
            </div> */}







                <div className="col-md-12 col-12">

                    {/* Display all the posts */}
                    {posts}

                    <div className="row">
                        <div className="col-md-3 col-12"></div>

                        <div className="col-md-6 col-12">


                            <ReactPaginate
                                previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg">PRE</i>}
                                nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg">NEXT</i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />


                        </div>

                    </div>
                    <div className="col-md-3 col-12">

                        <form 
                         onSubmit={searchCampaign} 
                        className='text-center'>
                            <input type="text" className="form-control" id="searchcamp" name="searchcamp"
                                placeholder="UserName" 
                                onChange={(e) => setCampaigns(e.target.value)}
                            // onChange={onInputChange}
                                />
                                <input type='submit' className='mt-1 btn btn-primary btn-mysm ' value="search" />

                        </form>

                    </div>



                </div>




            </div>
        </>

    )


}


export default Users
