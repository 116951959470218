import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios';
import { motion } from 'framer-motion';
import { BsFillUnlockFill } from "react-icons/bs";

import { BsArrowRightSquare } from 'react-icons/bs';
import { FcEmptyTrash } from 'react-icons/fc';
import { AiOutlineLogout } from "react-icons/ai";
import { FcOvertime } from "react-icons/fc";
import ReactPaginate from 'react-paginate';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { TbMessage2Search } from "react-icons/tb";
import { FcAddDatabase } from "react-icons/fc";
import { VscCallOutgoing } from "react-icons/vsc";
import { BsTelephoneX } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";

import './datetimeccustom.css'
import moment from 'moment';

const Leadsmanager = () => {

    useEffect(() => {
        document.title = "Leads-Manager"
    }, []);


    //All ref 
    const addleadref = useRef(null)
    const updateRemarksref = useRef(null)
    const updateleadstatusref = useRef(null)
    const callbackref = useRef(null)
    const searchbyleadstatusref = useRef(null)
    const searchbynumberref = useRef(null)
    const updateassignuserref = useRef(null)
    const editleaddetailsref = useRef(null)



    const [editleadleadid, setEditleadleadid] = useState("NA")
    const [editleadsource, setEditleadsource] = useState("NA")
    const [editleadnumber, setEditleadnumber] = useState("NA")
    const [editleadaltnumber, setEditleadaltnumber] = useState("NA")
    const [editleadname, setEditleadname] = useState("NA")
    const [editleademail, setEditleademail] = useState("NA")
    const [editleadstate, setEditleadstate] = useState("NA")
    const [editleaddistrict, setEditleaddistrict] = useState("NA")
    const [editleadpin, setEditleadpin] = useState("NA")
    const [editleadadress, setEditleadadress] = useState("NA")

    const editleaddetailsrefModelfun = (e1, e2, e3, e4, e5, e6, e7, e8, e9, e10) => {
        //alert("e5"+e5+"<<<")
        if (e2 === null) {
            e2 = 'NA';
        }
        if (e3 === null) {
            e3 = 'NA';
        }
        if (e4 === null) {
            e4 = 'NA';
        }
        if (e6 === null) {
            e6 = 'NA';
        }
        if (e7 === null) {
            e7 = 'NA';
        }
        if (e8 === null) {
            e8 = 'NA';
        }
        if (e9 === null) {
            e9 = 'NA';
        }
        if (e10 === null) {
            e10 = 'NA';
        }
        if (e5 === null) {
            e5 = 'NA';
        }

        setEditleadleadid(e1);
        setEditleadsource(e2);
        setEditleadnumber(e3);
        setEditleadaltnumber(e4);
        // setEditleadname();
        setEditleadname(e5);
        setEditleademail(e6);
        setEditleadstate(e7);
        setEditleaddistrict(e8);
        setEditleadpin(e9);
        setEditleadadress(e10);
        editleaddetailsref.current.click();

    }

    const editleaddetailsrefApifun = async (e) => {

        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")
        e.preventDefault();



        if (assignuserid === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Select User",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        var formdata = new FormData()
        //alert(">>>>>>>>"+assignuserid+">>>>>>>>"+assignuserleadid)


        formdata.append('leadid', editleadleadid)
        formdata.append('editleadsource', editleadsource)
        formdata.append('editleadaltnumber', editleadaltnumber)
        formdata.append('editleadname', editleadname)
        formdata.append('editleademail', editleademail)
        formdata.append('editleadstate', editleadstate)
        formdata.append('editleaddistrict', editleaddistrict)
        formdata.append('editleadpin', editleadpin)
        formdata.append('editleadadress', editleadadress)
        const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateLeadsDetails", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        setEditleadleadid('');
        setEditleadsource('');
        setEditleadnumber('');
        setEditleadaltnumber('');
        // setEditleadname();
        setEditleadname('');
        setEditleademail('');
        setEditleadstate('');
        setEditleaddistrict('');
        setEditleadpin('');
        setEditleadadress('');


        getAllPosts()



        editleaddetailsref.current.click();



    }

    const searchbyNumberModel = (e1) => {

        searchbynumberref.current.click();

    }

    const [searchbumber, setSearchbumber] = useState("NA");

    const searchbynumberfun = async (e) => {
        e.preventDefault();
        // alert(">>>>>>>>"+leadstatus)
        getAllPosts()
        searchbynumberref.current.click();
        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")


    }

    const searchbyleadstatusModel = (e1) => {

        searchbyleadstatusref.current.click();

    }

    const searchbyleadstatusfun = async (e) => {
        e.preventDefault();
        // alert(">>>>>>>>"+leadstatus)
        getAllPosts()
        searchbyleadstatusref.current.click();
        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")


    }





    const [myVar, setMyVar] = useState("NA");

    // Function to update the state variable and call another function
    const handleClicktodaycallback = () => {
        // Always update myVar (if it's not updated, keep it the same)
        if (myVar === "NA") {
            setMyVar("Today");
        }

        // Call myFunction whenever the button is clicked
        myFunction();
    };

    // Function that will be called when the button is clicked
    const myFunction = () => {
        getAllPosts()
        //console.log("myVar is now:", myVar);
        //alert(`myVar is now: ${myVar}`);

    };


    const [callbacktimevar, setCallbacktimevar] = useState("NA");

    const [callbacktimevarnew, setCallbacktimevarnew] = useState("NA");
    //const [callbackid, setCallbackid] = useState("NA");
    //var ab = "";

    const getAllTodayleadsModel = () => {

        setCallbacktimevarnew("");
        //e.preventDefault();
        //  ab = 'Today';
        if (callbacktimevar === 'NA') {
            // Check if it's still the initial value
            // alert(">>>")
            //setLeadstatusvar("NA")
            // setSearchbumber("NA")
            setCallbacktimevarnew("Today");
            //  testModel()
            // setOffset(1)
            // getAllPosts()
        } else {
            setCallbacktimevarnew("Today");
            //getAllPosts()

        }


        //setLeadstatusvar("")
        // setSearchbumber("NA")
        //setCallbacktimevar("NA")
        //alert(">>>>>>>>"+callbacktimevar)
        //getAllPostsAllTodaydata()

        // searchbyleadstatusref.current.click();
        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")


    }
    const testModel = async () => {
        // alert(">>>>>>>"+callbacktimevarnew)
        setCallbacktimevar("")

    }

    useEffect(() => {
        if (callbacktimevarnew !== "NA") {
            testModel();  // Call the function if myVar is not the initial value
        }
    }, [callbacktimevarnew]);



    const getAllleadsModel = async () => {
        //e.preventDefault();
        // alert(">>>>>>>>"+leadstatus)
        // LeadStatus=${leadstatusvar}&&Searchbumber=${searchbumber}
        setLeadstatusvar("NA")
        setSearchbumber("NA")
        //setCallbacktimevar("NA")
        setOffset(1)
        // getAllPosts()

        getAllPostsAlldata()
        // searchbyleadstatusref.current.click();
        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")


    }



    const [callbackid, setCallbackid] = useState();

    const callbackModel = (e1) => {

        //alert(e)
        setCallbackid(e1);
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        // setUsername('')
        // setPassword('')

        callbackref.current.click();


    }


    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };



    const updateCallBackfun = async (e) => {

        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")
        e.preventDefault();
        if (selectedDate === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Select Date For CALLBACK",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        var formdata = new FormData()
        formdata.append('callbacktime', moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
        formdata.append('leadid', callbackid)
        const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateLeadManagercallback", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()



        callbackref.current.click();



    }







    const addLeadModel = () => {

        //alert(e)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        // setUsername('')
        // setPassword('')

        addleadref.current.click();


    }








    const [leadname, setLeadname] = useState("NA")
    const [leadnumber, setLeadnumber] = useState("NA")
    const [leadaltnumber, setLeadaltnumber] = useState("NA")
    const [leademail, setLeademail] = useState("NA")
    const [leadstate, setLeadstate] = useState("NA")
    const [leaddis, setLeaddis] = useState("NA")
    const [leadpin, setLeadpin] = useState("NA")
    const [leadadr, setLeadadr] = useState("NA")
    const [leadrem, setLeadrem] = useState("NA")
    const [leadoth, setLeadoth] = useState("NA")


    const [leadid, setLeadid] = useState()
    const updateRemarks = (e1, e2) => {

        //alert(e)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        // setUsername('')
        // setPassword('')
        setLeadid(e1);
        setLeadrem(e2);

        updateRemarksref.current.click();


    }


    const [updlid, setUpdlid] = useState()
    const [lstatus, setLstatus] = useState()
    const updateStatus = (e1, e2) => {

        allLeadstatus();
        //alert(">>>>" + e2)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        // setUsername('')
        // setPassword('')
        setUpdlid(e1);
        setLstatus(e2);

        updateleadstatusref.current.click();


    }

    const [assignuserleadid, setAssignuserleadid] = useState()
    const [assignuser, setAssignuser] = useState()
    const updateAssignuser = (e1, e2) => {

        allLeadstatus();
        //alert(">>>>" + e2)
        // setChagePwdid(e1);
        // setChagePwdusername(e2);

        // setUsername('')
        // setPassword('')
        setAssignuserleadid(e1);
        setAssignuser(e2);

        updateassignuserref.current.click();


    }






    //callCallManager

    const callCallManager = async (e1, e2) => {
        //alert(">>>>>>>"+e1+"########"+e2+"<<<<<<<<<<<<")
        //e.preventDefault();
        var formdata = new FormData()
        formdata.append('number', e1)
        formdata.append('leadid', e2)
        const response = await fetch(process.env.REACT_APP_IP + "/User/callCallManagerApi", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

    }


    const updateLeadStatusApiCall = async (e) => {
        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")
        e.preventDefault();
        var formdata = new FormData()
        formdata.append('leadstatus', leadstatusvar)
        formdata.append('leadid', updlid)
        const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateLeadManagerStatus", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()



        updateleadstatusref.current.click();



    }





    const updateRemarksLeadApiCall = async (e) => {
        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")
        e.preventDefault();
        var formdata = new FormData()
        formdata.append('leadstatus', leadrem)
        formdata.append('leadid', leadid)
        const response = await fetch(process.env.REACT_APP_IP + "/User/updateRemarksLeadApi", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()



        updateRemarksref.current.click();



    }


    const hangupAgent = async () => {
        // e.preventDefault();
        // var formdata = new FormData()
        // formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/User/HangupAgentApi", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



    }


    const addLeadApiCall = async (e) => {

        // alert(leadname)
        // alert(leadnumber)
        // alert(leadaltnumber)
        // alert(leademail)
        // alert(leadstate)
        e.preventDefault();
        var formdata = new FormData()
        formdata.append('leadnumber', leadnumber)
        formdata.append('leadaltnumber', leadaltnumber)
        formdata.append('leadname', leadname)
        formdata.append('leademail', leademail)
        formdata.append('leadstate', leadstate)
        formdata.append('leaddis', leaddis)
        formdata.append('leadpin', leadpin)
        formdata.append('leadadr', leadadr)
        formdata.append('leadrem', leadrem)
        formdata.append('leadoth', leadoth)

        const response = await fetch(process.env.REACT_APP_IP + "/User/AddLead", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()


        setLeadname("NA")
        setLeadnumber("NA")
        setLeadaltnumber("NA")
        setLeademail("NA")
        setLeadstate("NA")
        setLeaddis("NA")
        setLeadpin("NA")
        setLeadadr("NA")
        setLeadrem("NA")
        setLeadoth("NA")


        addleadref.current.click();


    }




    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {


        // alert("getallpost>>>>"+callbacktimevar)
        //setOffset(1)

        const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllLead?pageNumber=${offset - 1}&&size=10&&LeadStatus=${leadstatusvar}&&Searchnumber=${searchbumber}&&Callbacktime=${myVar}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        // const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllLead?pageNumber=${offset - 1}&&size=10&&LeadStatus=${leadstatusvar}&&Searchnumber=${searchbumber}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })




        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const getAllPostsAlldata = async () => {
        // setOffset(1)
        const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllLead?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))

    }

    const getAllPostsAllTodaydata = async () => {
        //setCallbacktimevar(callbacktimevar)
        // const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllLead?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

        // setOffset(1)

        const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllLead?pageNumber=${offset - 1}&&size=10&&Callbacktime=Today`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })


        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }








    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])




    const [leadstatusvar, setLeadstatusvar] = useState("NA");

    const allLeadstatus = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllLeadstatusdrop", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('updateleadstatusid');
        elelist.innerHTML = "" + ('<option value="">NA</option>');

        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['leadstatus'] + '">' + json[i]['leadstatus'] + '</option>';
        }


        var elelistforseach = document.getElementById('updateleadstatusidforsearch');
        elelistforseach.innerHTML = "" + ('<option value="NA">ALL</option>');

        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelistforseach.innerHTML = elelistforseach.innerHTML +
                '<option  value="' + json[i]['leadstatus'] + '">' + json[i]['leadstatus'] + '</option>';
        }








    }

    useEffect(() => {
        allLeadstatus()
    }, [])

    const [assignuserid, setAssignuserid] = useState()


    const optionforusername = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP + "/Admin/RestAllUserdrop", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('usernameiddropdown');
        elelist.innerHTML = "" + ('<option value="">NA</option>');

        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | ' + json[i]['username'] + '</option>';
        }





    }

    useEffect(() => {
        optionforusername()
    }, [])



    const updateAssignuserfun = async (e) => {

        //alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

        // alert(">>>>>>>"+leadstatus+"########"+updlid+"<<<<<<<<<<<<")
        e.preventDefault();
        if (assignuserid === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Select User",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        var formdata = new FormData()
        //alert(">>>>>>>>"+assignuserid+">>>>>>>>"+assignuserleadid)
        formdata.append('userid', assignuserid)
        formdata.append('leadid', assignuserleadid)
        const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateAssignUser", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }



        getAllPosts()



        updateassignuserref.current.click();



    }



    const getPostData = (data) => {
        return (
            <>






                <tbody>
                    {data.content.map(page => {
                        return (
                            <tr key={page.id} className='text-center'>
                                <td>{page.id}</td>
                                <td>


                                    <i style={{ cursor: 'pointer' }} className=" fa fa-edit btn btn-primary btn-mysm  "
                                        onClick={() => editleaddetailsrefModelfun(
                                            page.id, page.other, page.number, page.altnumber, page.name, page.email,
                                            page.state,
                                            page.district,
                                            page.pin,
                                            page.address

                                        )}
                                    ></i>

                                </td>
                                <td>{page.other}</td>
                                <td> <Link to="/Leadslog" className='btn btn-primary btn-mysm' state={{ id: page.id }}>
                                    <TbReportSearch size="20" />

                                </Link></td>

                                <td>{page.number}</td>

                                {localStorage.getItem('roles') === 'ROLE_USER' ?
                                    <td>
                                        <button className=" btn btn-info btn-mysm mx-1"
                                            onClick={() => callCallManager(page.number, page.id)}

                                        >
                                            <VscCallOutgoing color='blue' />

                                        </button>
                                    </td> : ""}


                                {localStorage.getItem('roles') === 'ROLE_USER' ?
                                    <td>
                                        <button className=" btn btn-danger btn-mysm mx-1"
                                            onClick={() => hangupAgent()} >
                                            <BsTelephoneX /></button>
                                    </td> : ""}

                                <td>

                                    <button className=" btn btn-secondary btn-mysm "
                                        onClick={() => callbackModel(page.id)}

                                    >
                                        <FcOvertime size="25" />

                                    </button>






                                </td>
                                <td>{page.callbacktime}</td>

                                <td>{page.altnumber}</td>
                                <td>{page.name}</td>
                                <td>{page.leadstatus}</td>
                                <td>


                                    <i style={{ cursor: 'pointer' }} className=" fa fa-edit btn btn-primary btn-mysm  "
                                        onClick={() => updateStatus(page.id, page.leadstatus)}></i>

                                </td>

                                {/* <td>{page.agentname}</td> */}
                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <td>{page.agentname}</td> : ""}


                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <td>

                                        <i style={{ cursor: 'pointer' }} className=" fa fa-edit btn btn-primary btn-mysm  "
                                            onClick={() => updateAssignuser(page.id, page.agentname)}></i>
                                    </td> : ""}

                                <td>
                                    {/* <div className='row'>
                                                                    <div className='col-md-10'> */}
                                    <textarea
                                        value={page.remarks}         // Value comes from React state
                                        // onChange={handleChange}  // Change event to update state
                                        rows="2"
                                        cols="40"


                                    />
                                    {/* </div>
                                                            <div className='col-md-2'> */}
                                    {/*                                                            
                                                                <i style={{ cursor: 'pointer'}} className=" fa fa-edit float-end " onClick={() => updateRemarks(page.id,page.remarks)}></i>
                                                                 */}

                                    {/* </div>
                                                                </div> */}

                                    {/* {page.remarks}<i className="fa far fa-edit" onClick={() => updateRemarks(page.id,page.remarks)}></i> */}


                                </td>
                                <td>


                                    <i style={{ cursor: 'pointer' }} className=" fa fa-edit btn btn-primary btn-mysm  "
                                        onClick={() => updateRemarks(page.id, page.remarks)}></i>

                                </td>


                                <td>{page.insertdate}</td>


                                <td>{page.modifieddate}</td>
                                <td>
                                    <button className=" btn btn-danger btn-mysm "
                                    //onClick={() => logoutAgent(page.id)}

                                    >
                                        <i className="fa fa-trash" ></i>
                                    </button>
                                </td>


                                <td>{page.email}</td>

                                <td>{page.state}</td>
                                <td>{page.district}</td>
                                <td>{page.pin}</td>
                                <td>{page.address}</td>









                                {/* <td className='text-center'>
                                                               

                                                            </td> */}
                                {/* <td> <i className="far fa-trash-alt mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}></i></td> */}




                            </tr>
                        );
                    })}
                </tbody>

                {/* Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} /> */}





            </>

        )

    }





    return (
        <>

  <div className=''>

            {/* EditLeaddetails  Model */}
            <button ref={editleaddetailsref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#editleaddetailsmodel">
                EditLead Details
            </button>
            <div className="modal fade" id="editleaddetailsmodel" tabIndex="-1" aria-labelledby="editleaddetailsmodel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editleaddetailsmodel">EditLead Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={editleaddetailsrefApifun}
                            >
                                <div className='card-header text-white mb-2 bg-primary'>
                                    <div className=" row d-flex align-items-center">
                                        <label className='text-center p-1'>EditLead Details</label>
                                    </div>
                                    {/* <h4 className='text-center'>EditLead Details</h4> */}
                                </div>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>LeadId</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadleadid}
                                            //onChange={(e) => setSearchbumber(e.target.value)}
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Lead Source</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadsource}
                                            onChange={(e) => setEditleadsource(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Number</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadnumber}
                                            //onChange={(e) => setSearchbumber(e.target.value)}
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Alt Number</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadaltnumber}
                                            onChange={(e) => setEditleadaltnumber(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Name</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadname}
                                            onChange={(e) => setEditleadname(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Email</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleademail}
                                            onChange={(e) => setEditleademail(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>State</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadstate}
                                            onChange={(e) => setEditleadstate(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>District</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleaddistrict}
                                            onChange={(e) => setEditleaddistrict(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12">
                                        <label className='ms-1 '>Pin Code</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadpin}
                                            onChange={(e) => setEditleadpin(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <hr class="my-1"></hr>
                                <div className=" row d-flex align-items-center">
                                    <div className="col-md-4 col-12 ">
                                        <label className='ms-1 '>Address</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder=""

                                            value={editleadadress}
                                            onChange={(e) => setEditleadadress(e.target.value)}

                                        />

                                    </div>
                                </div>
                                <input className="btn btn-primary btn-sm mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>

            {/* Search By UpdateAssignuser  Model */}
            <button ref={updateassignuserref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#updateassignuserm">
                UpdateAssignuser
            </button>
            <div className="modal fade" id="updateassignuserm" tabIndex="-1" aria-labelledby="updateassignuserm" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateassignuserm">Update Assign-User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={updateAssignuserfun}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4 className='text-center'>Update Assign-User</h4>
                                </div>
                                <div className=" row mt-2">
                                    <div className="col-md-4 col-12">
                                        <label>LeadId</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder="searchbumber"

                                            value={assignuserleadid}
                                            //onChange={(e) => setSearchbumber(e.target.value)}
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <div className=" row mt-2">
                                    <div className="col-md-4 col-12">
                                        <label>Assign To User</label>
                                    </div>
                                    <div className=" col-md-8 ">
                                        <select id="usernameiddropdown" className="form-select" onChange={(e) => setAssignuserid(e.target.value)} required>
                                            {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                    <option value=""></option> */}



                                        </select>

                                    </div>
                                </div>
                                <input className="btn btn-primary btn-sm mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>



            {/* Search By searchbynumber  Model */}
            <button ref={searchbynumberref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#searchbynumber">
                Search By Number
            </button>
            <div className="modal fade" id="searchbynumber" tabIndex="-1" aria-labelledby="searchbynumber" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchbynumber">Search By Number</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={searchbynumberfun}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4 className='text-center'>Search By Number</h4>
                                </div>
                                <div className=" row mt-2">
                                    <div className="col-md-3 col-12">
                                        <label>Number</label>
                                    </div>
                                    <div className=" col-md-9 ">
                                        <input type="text" className="form-control" id="searchbumberid" placeholder="searchbumber"

                                            value={searchbumber}
                                            onChange={(e) => setSearchbumber(e.target.value)}
                                        />

                                    </div>
                                </div>
                                <input className="btn btn-primary btn-sm mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>

            {/* Search By LeadStatus  Model */}
            <button ref={searchbyleadstatusref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#searchbyleadstatus">
                Search By LeadStatus
            </button>
            <div className="modal fade" id="searchbyleadstatus" tabIndex="-1" aria-labelledby="searchbyleadstatus" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchbyleadstatus">Search By LeadStatus</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={searchbyleadstatusfun}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4 className='text-center'>Search By Lead Status</h4>
                                </div>
                                <div className=" row mt-2">
                                    <div className="col-md-3 col-12">
                                        <label>Lead Status</label>
                                    </div>
                                    <div className=" col-md-9 ">
                                        <select id="updateleadstatusidforsearch" className="form-select" name="bulkuserid"
                                            onChange={(e) => setLeadstatusvar(e.target.value)}
                                        >
                                        </select>

                                    </div>
                                </div>
                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>




            {/* Set Call Back Model */}


            <button ref={callbackref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#callbackrefModal">
                Call Back
            </button>
            <div className="modal fade" id="callbackrefModal" tabIndex="-1" aria-labelledby="callbackrefModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="callbackrefModal">Call Back / Reminder</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">


                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={updateCallBackfun}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4 className='text-center'>Reminder</h4>
                                </div>

                                <div className=" row">


                                    <div className="col-md-3 col-12">
                                        <label>LeadId</label>
                                    </div>
                                    <div className=" col-md-9 ">

                                        <input type="text" className="form-control" id="Leadid" placeholder="Leadid"
                                            name="Leadid"
                                            value={callbackid}
                                            //  onChange={(e) => setUpdlid(e.target.value)} 
                                            readOnly />

                                    </div>




                                </div>



                                <div className='mt-2 mb-2'>


                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <FcOvertime size="30" />
                                        </div>
                                        <div className='col-md-9'>
                                            <DateTime
                                                className='rdtmy'
                                                value={selectedDate}
                                                onChange={setSelectedDate}


                                                timeFormat="HH:mm:ss"
                                                inputProps={{ placeholder: 'Select Date and Time' }}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>






                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>






            {/* Update Lead Status  Model */}


            <button ref={updateleadstatusref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#updateleadstatusrefModal">
                Update Lead Status
            </button>
            <div className="modal fade" id="updateleadstatusrefModal" tabIndex="-1" aria-labelledby="updateleadstatusrefModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateleadstatusrefModal">Update Lead Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">


                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={updateLeadStatusApiCall}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4>Update Lead Status</h4>
                                </div>
                                <div className=" row">


                                    <div className="col-md-12 col-12">
                                        <label>LeadId</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Remarks"
                                                name="username"
                                                value={updlid}
                                                //  onChange={(e) => setUpdlid(e.target.value)} 
                                                readOnly />

                                        </div>

                                    </div>

                                </div>

                                <div className=" row">


                                    <div className="col-md-12 col-12">
                                        <label>LeadStatus</label>
                                        <div className=" mb-2 ">

                                            <select id="updateleadstatusid" className="form-select" name="bulkuserid"
                                                onChange={(e) => setLeadstatusvar(e.target.value)}

                                                required>
                                            </select>

                                        </div>

                                    </div>

                                </div>




                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>


            {/* Update Remarks Lead Model */}


            <button ref={updateRemarksref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#updateremarkModal">
                Add User
            </button>
            <div className="modal fade" id="updateremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateremarkModal">Update Remarks</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">


                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={updateRemarksLeadApiCall}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4>Update Remarks</h4>
                                </div>
                                <div className=" row">


                                    <div className="col-md-12 col-12">
                                        <label>LeadId</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Remarks"
                                                name="username"
                                                value={leadid}
                                                //onChange={(e) => setLeadrem(e.target.value)} 
                                                readOnly />

                                        </div>

                                    </div>

                                </div>

                                <div className=" row">


                                    <div className="col-md-12 col-12">
                                        <label>Name</label>
                                        <div className=" mb-2 ">
                                            <textarea className="form-control"
                                                value={leadrem}        // Value comes from React state
                                                // onChange={handleChange}  // Change event to update state
                                                onChange={(e) => setLeadrem(e.target.value)}
                                                rows="2"
                                                cols="40"


                                            />

                                            {/* <input type="text-area" className="form-control" id="username" placeholder="Remarks"
                                                name="username"
                                                value={leadrem}
                                                onChange={(e) => setLeadrem(e.target.value)}
                                                required /> */}

                                        </div>

                                    </div>

                                </div>




                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </form>

                        </div>

                    </div>
                </div>
            </div>



            {/* Add Lead Model */}


            <button ref={addleadref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#addleadModal">
                Add User
            </button>
            <div className="modal fade" id="addleadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addleadModal">Add Lead</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">


                            <form className='card p-1 mt-1 mybgcolor mx-1' style={{
                                minHeight: "300px"

                            }}
                                onSubmit={addLeadApiCall}
                            >
                                <div className='card-header text-black mb-2'>
                                    <h4>Add Lead</h4>
                                </div>

                                <div className=" row">
                                    <div className="col-md-6 col-12">
                                        <label>Number</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Enter Number" title="NA For Not Available"
                                                name="username"
                                                value={leadnumber}
                                                onChange={(e) => setLeadnumber(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label>Alternate Number</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Alternate Number" title="NA For Not Available"
                                                name="username"
                                                value={leadaltnumber}
                                                onChange={(e) => setLeadaltnumber(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                </div>
                                <div className=" row">
                                    <div className="col-md-6 col-12">
                                        <label>Name</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Enter Name"
                                                name="username"
                                                value={leadname}
                                                onChange={(e) => setLeadname(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label>Email</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="Enter Email"
                                                name="username"
                                                value={leademail}
                                                onChange={(e) => setLeademail(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                </div>
                                <div className=" row">
                                    <div className="col-md-6 col-12">
                                        <label>State</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leadstate}
                                                onChange={(e) => setLeadstate(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label>District</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leaddis}
                                                onChange={(e) => setLeaddis(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                </div>
                                <div className=" row">
                                    <div className="col-md-6 col-12">
                                        <label>Pincode</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leadpin}
                                                onChange={(e) => setLeadpin(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label>Address</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leadadr}
                                                onChange={(e) => setLeadadr(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                </div>
                                <div className=" row">
                                    <div className="col-md-6 col-12">
                                        <label>Remarks</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leadrem}
                                                onChange={(e) => setLeadrem(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label >Lead Source</label>
                                        <div className=" mb-2 ">

                                            <input type="text" className="form-control" id="username" placeholder="" title="NA For Not Available"
                                                name="username"
                                                value={leadoth}
                                                onChange={(e) => setLeadoth(e.target.value)}
                                                required />

                                        </div>

                                    </div>
                                </div>

                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </form>

                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>



                    
            
            
                           

                       

                        {/* Display all the posts */}
                        {/* <div style={{minHeight:"700px"}}> */}
                        <div className=''>
                        
                        <div className='ms-1 me-3 mt-1'>
                        <div className='card'>
                           <div className='card-header'>
                                <h1 className=' text-center'>Existing Leads</h1>
                            </div>                           
                                <div className='row mt-1  mb-1 ms-2'>
                                    <div className='col-md-2 col-6 '>
                                        <button className='btn btn-primary btn-sm '
                                            onClick={() => addLeadModel()}
                                        >AddLead <FcAddDatabase size="20" className='float-start me-1' /></button>
                                    </div>
                                    <div className='col-md-2 col-6'>


                                        <button className='btn btn-info btn-sm ' onClick={() => searchbyNumberModel()}>Number Search
                                            <TbMessage2Search size="20" className='float-start me-1' /></button>
                                    </div>
                                    <div className='d-md-none mt-1 mb-1'>

                                    </div>
                                    <div className='col-md-2 col-6'>

                                        <button className='btn btn-info btn-sm mx-1 '
                                            onClick={handleClicktodaycallback}
                                        >Today Reminder<TbMessage2Search size="20"
                                            className='float-start me-1' /></button>
                                    </div>
                                    <div className='col-md-2 col-6'>
                                        <button className='btn btn-info btn-sm ' onClick={() => searchbyleadstatusModel()}>Lead Status
                                            <TbMessage2Search size="20" className='float-start me-1' /></button>

                                    </div>
                                    <div className='d-md-none mt-1 mb-1'>

                                    </div>

                                    <div className='col-md-2 col-6'>

                                        <button className='btn btn-info btn-sm mx-1' onClick={() => getAllleadsModel()}>
                                            <TbMessage2Search size="20" className='float-start me-1' /> All Lead Data </button>




                                    </div>

                                </div>
                                <div className='card-body'>
                                 <div className='table-responsive'> 
                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white ">

                                    <tr>
                                        <th>ID</th>
                                        <th>Edit</th>
                                        <th>Lead Source</th>
                                        <th> Lead-Log</th>

                                        <th>Number</th>
                                        {localStorage.getItem('roles') === 'ROLE_USER' ?
                                            <th>
                                                Call </th> : ""}
                                        {localStorage.getItem('roles') === 'ROLE_USER' ?
                                            <th>
                                                Cut </th> : ""}
                                        <th>Reminder</th>
                                        {/* <th>Lead-Date</th> */}
                                        {/* Aditya CRM Uncomment Above Coment Below */}
                                        <th>Reminder-Date</th>

                                        <th>AltNumber</th>
                                        <th>Name</th>
                                        <th>LeadStatus</th>

                                        <th></th>
                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                            <th>Assign User</th> : ""}

                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                            <th>Switch User</th> : ""}

                                        {/* <th>Assign User</th> */}
                                        <th>Remarks</th>


                                        <th></th>
                                        <th>Date</th>
                                        <th>LastActionDate</th>
                                        <th>Delete</th>

                                        <th>Email</th>
                                        <th>State</th>
                                        <th>District</th>
                                        <th>Pincode</th>
                                        <th>Address</th>




                                        {/* <th>Delete</th> */}


                                    </tr>
                                </thead>
                                {posts}
                            </table>
                            

                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <ReactPaginate
                                        nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                                        previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}

                                        breakLabel={".."}
                                        breakClassName={"break-me"}
                                        breakLinkClassName={"break-mef"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        forcePage={offset - 1}

                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination2"}
                                        activeClassName={"active"} />

                                </div>
                                <div className='col-md-4'></div>
                            </div>

                            </div>
                            </div>

                        </div>
                        </div>
                        </div>     
                        </div>              
        </>

    )
}

export default Leadsmanager