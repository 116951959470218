import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';

import Swal from 'sweetalert2';
import Assiglist from './Assignlist';
import { RotatingLines } from 'react-loader-spinner';




const UploadNumber = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Upload Number"
     }, []);

     const [downloadtrue, setDownloadtrue] = useState(false)
     
    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [offsetforassignlist, setOffsetforassignlist] = useState(1);
    const [postsforassignlist, setAllpostsforassignlist] = useState([]);
     const [euserid, seteuserid] = useState("ALL");
     const optionforusername = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllUserdrop", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
        
        var elelist = document.getElementById('usernameiddropdown');
        elelist.innerHTML = ""+('<option value="ALL">ALL</option>');
        
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['username'] + '">' + json[i]['username'] + '</option>';
        }
      
      
        
      
      
      }
      
      useEffect(() => {
          optionforusername()
      }, [])

const DownloadList = (e) => 
{
    window.open(process.env.REACT_APP_IP + `/Admin/reportdownloadByListname`+`?listid=`+e);
}

    const csvData = [
        ["Number","C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"]
    ];

    const filterreportdownload = async (e) => {
        setDownloadtrue(true)
        try {
            const response = await fetch(process.env.REACT_APP_IP + `/User/DatadownloadByListname?listname=${e}`, {
                method: 'GET',
                headers: {
                    // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (response.status === 200) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'report.csv';
                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                console.error('Error downloading CSV:', response.status);
                setDownloadtrue(false)
            }
            setDownloadtrue(false)
        } catch (error) {
            console.error('Error downloading CSV:', error);
            setDownloadtrue(false)
        }

    }

    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }


    const [groupname,setGroup] = useState()
    const [elistid,setElistid] = useState()

    const submitAssignList = async (e) => {
        e.preventDefault();
        //refaddgroupagent.current.click();
        console.log("groupname>>>>>>>>" + groupname)
        console.log("euserid>>>>>>>>" + euserid)
        var formdata = new FormData()
        formdata.append('campid', groupname)
        formdata.append('listid', elistid)
        const response = await fetch(process.env.REACT_APP_IP+"/User/AssignList", {
            method: 'POST',     
            headers: {
                //  'Content-Type': 'application/json',
    
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
    
    
       // setGroupname("NA")
      //  set
       
        getAllPostsForAssignlist()
    
    
    
    }

    

    const [pageCountlist, setPageCountlist] = useState(0)

const getAllPostsForAssignlist = async () => {
    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllAssignlist?pageNumber=${offsetforassignlist - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    const data = res.data;
    const postData = getPostDataforAssignlist(data)
    // Using Hooks to set value
    setAllpostsforassignlist(postData)
    // setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCountlist(Math.ceil(data.totalPages))
}
useEffect(() => {
    getAllPostsForAssignlist()
}, [offsetforassignlist])


const getPostDataforAssignlist = (data) => {
    return (
        <>

            <div className='row '>

                {/* <AddCampaignform /> */}


                < div className='col-md-12 col-12'>

                    <div className='card mt-5 p-1' >



                        <div className=''>
                                <div className='card-header text-black'>
                                    {/* <h4 className='text-center'>Existing Assign List</h4> */}
                                    <div className='text-center'>Existing Assign List</div>
                                </div>

                            <div className="table-responsive p-1">

                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white">
                                        <tr>
                                            <th>ID</th>
                                           
                                            <th>List Id | Name</th>
                                            
                                            <th>CampaignId | Campname</th>
                                           
                                            <th>Assign Date</th>
                                            

                                            <th>Action</th>
                                            {/* <th>Delete</th> */}


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.content.map(page => {
                                            return (
                                                <tr key={page.id}>
                                                    <td>{page.id}</td>
                                                   
                                                    <td>{page.listid} | {page.listname} </td>
                                                    
                                                    <td>{page.campid} | {page.campname} </td>
                                                   
                                                    <td>{page.dateadded}</td>
                                                    

                                                    <td>
                                                        {/* <button className=" mx-2 btn btn-primary disabled" >
                                                            <i className="fa fa-edit" ></i>
                                                        </button> */}
                                                  <button className='btn btn-danger btn-mysm'>
                                                        
                                                  <i className="fa fa-trash mx-2" onClick={() => deleteassignagent(page.id)}></i>

                                                  </button>
                                                

                                                    </td>





                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                Goto Pageno:<input type="text" className="col-md-4 mx-2" id="gotopage" aria-describedby="gotopage"
                                    placeholder="gotopage" onChange={onInputChange} />

                            </div>
                        </div>
                    </div>


                </div>

            </div>


        </>

    )

}


const deleteassignagent = async (e) => {
    // e.preventDefault();
     //refaddgroupagent.current.click();
     console.log("id>>>>>>>>" + e)
    
     var formdata = new FormData()
     formdata.append('id', e)
   
     const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteAssignlist", {
         method: 'POST',     
         headers: {
             //  'Content-Type': 'application/json',
 
             "Authorization": `Bearer ${localStorage.getItem('token')}`
         },
         body: formdata
         // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
     });
     const json = await response.json()
     console.log(json);
     if (json.type === "OK") {
         // props.showAlert(json.msg, "success");
         Swal.fire({
             position: 'top-end',
             icon: 'success',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     } else {
         //props.showAlert(json.msg, "danger");
         Swal.fire({
             position: 'top-end',
             icon: 'error',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     }
 
 
    // setGroupname("NA")
   //  set
    
     getAllPostsForAssignlist()
 
 
 
 }

    const optionforlist = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllListname", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
    
            headers: {
                'Content-Type': 'application/json',
    
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
    
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
    
        var elelist = document.getElementById('list');
        elelist.innerHTML = ""+('<option value="0">NA</option>');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['id'] +' | '+ json[i]['listname'] + '</option>';
        }
    
    }
    
    useEffect(() => {
        optionforlist()
    }, [])


    const optionforgroup = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
    
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
    
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
    
        var elelist = document.getElementById('groupselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');
    
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['campname'] + '</option>';
        }
    }
    
    useEffect(() => {
        optionforgroup()
    }, [])


    const uploadNumbersubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)
        formData.append('listname', listname)
        formData.append('agent', euserid)

        const response = await fetch(process.env.REACT_APP_IP+"/User/UploadNumber", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()
        optionforlist()

        el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


     // deleteCampaign
     const deletelist = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteList", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if(json.type==="OK")
        {
           // props.showAlert(json.msg, "success");
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }else{
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 2500
              })
        }
        
        getAllPosts()
        




    }


    const downloadsample = async (e) => {
        e.preventDefault();

        const response = await fetch(process.env.REACT_APP_IP+"/User/api/foo.csv", {
            method: 'GET',
            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
        const json = await response.json()
        // console.log(json)
        props.showAlert(" Downloaded Successfully ", "success");
        getAllPosts()
    }







    
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllList?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handlePageClicklist = (event) => {
        const selectedPagenew = event.selected;
        setOffsetforassignlist(selectedPagenew + 1)
    };

    useEffect(() => {
        getAllPosts()

       // callSecondPageFunction();

    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <div className='row mt-1'>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-3'>

                        <div className='card   p-1 mt-2'>

                            <div className=''>
                                 <div className='card-header text-black'>
                                  
                                   <div className='text-center'>Existing List</div>
                                </div>

                                <div className="table-responsive mt-1">

                                    <table className="table table-hover table-bordered checkoddata ignoreline">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>ListName</th>
                                                <th>Download</th>
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.listname}</td>
                                                        <td className='text-center'>
                                                                <button className='btn btn-success btn-mysm'>
                                                                    
                                                                    {/* <i className="fa fa-download " onClick={() => DownloadList(page.listname)} ></i> */}
                                                                    <i className="fa fa-download " onClick={() => filterreportdownload(page.listname)} ></i>

                                                                </button>
                                                             
                                                         </td>

                                                         <td className='text-center'>
                                                                <button className='btn btn-danger btn-mysm'>
                                                                    <i className="fa fa-trash mx-2 " onClick={() => deletelist(page.id)} ></i>
                                                                </button>
                                                             
                                                         </td>
                                                             




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-4 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (


       <>
      
      <div className='row border-bottom  border-3 border-success mb-5 p-2'>
            <div className='col-md-6 col-12 border-end  border-3 border-success'>
           

            <div className={downloadtrue ? "text-center mt-5" : "text-center mt-5 hide"}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              visible={true}
            />

          </div>

        <div className='row mt-1 ms-1'>

            
           
           

                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                <div className='col-md-12 col-12'>
                <div className='row'>
                    <div className='col-md-2 col-12'>
                    </div>
                    <div className='col-md-8 col-12'>
                <div className='card ms-1 me-2 mt-2 '>
                    <form className='p-4'  onSubmit={uploadNumbersubmit}>
                        
                          
                        <div className='card-header text-center'>
                            Upload Number
                        </div>
                        
                        <div className="">
                                                    <label htmlFor="user" className="form-label">User Name</label>
                                                    <select id="usernameiddropdown" className="form-select"  onChange={(e) => seteuserid(e.target.value)} required>
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                    <option value=""></option> */}
                                                                    


                                                    </select>
                                                </div>
                        <div className=" mt-1">
                            <label htmlFor="listname">List Name</label>
                            <input name="listname" type="text" className=" form-control form-control-sm"
                                onChange={(e) => setListname(e.target.value)} required />


                        </div>

                        <div className="mt-3 ">


                            <input className=" form-control form-control-sm" ref={el} accept=".csv"
                                id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                        </div>
                        <div className='row '>
                        <div className='col-md-12'>
                                <input className=" form-control btn btn-primary  mt-3 text-white" type="submit" value="Submit" />
                            </div>
                            <div className='col-md-12'>
                                {/* <i className="fa-solid fa-download fa-2x btn btn-success  mt-3 " onClick={downloadsample} ></i> */}




                             <CSVLink className=" btn btn-success mt-3 text-white float-end btn-mysm" 
                                data={csvData} filename={"SampleFile.csv"} target="_blank" >
                                    <i className="fa fa-download mx-1"></i>
                                     CSV Format
                              </CSVLink>



                                {/* or  */}
                                {/* <CSVDownload data={csvData} target="_blank" />; */}
                            </div>
                            

                        </div>



                    </form>
                    </div>
                    </div>
                    </div>

                </div>
            
              

                <div className="col-md-12 col-12">
                

                    {/* Display all the posts */}
                    {posts}

                    <div className="row">
                        <div className="col-md-3 col-12"></div>

                        <div className="col-md-6 col-12">


                            <ReactPaginate
                                previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}
                                nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />


                        </div>

                    </div>


                    
                </div>


                </div>

      </div>

      <div className='col-md-6 col-12   border-3 border-success'>
            


            <div className='row'>
               <div className='col-md-2'>
                </div>
            <div className='col-md-8 mt-2'>
                     
                     <div className='card p-4'>
                          <div className='card-header text-center'>
                                Assign List To Campaign
                          </div>
                          <div className=''>
                                <form onSubmit={submitAssignList}>
                                          <div className=" mb-1">
                                                <b htmlFor="groupname">Select Campaign:</b>
                                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                <select id="groupselectmodel" className="form-select mt-2" onChange={(e) => setGroup(e.target.value)}
                                                    required>
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                                            <option value=""></option> */}


                                                </select>

                                            </div>
                                            {/* <div className="dropdown-divider"></div> */}
                                            <div className="mb-3">
                                                <label htmlFor="user" className="form-label">Select List</label>
                                                <select id="list" className="form-select"  onChange={(e) => setElistid(e.target.value)} required>
                                                {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                <option value=""></option> */}
                                                                


                                                </select>
                                            </div>
                                            
                                   <div className="d-grid gap-2">
                                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                    </div>


                                </form>
                          </div>

                     </div>
                 </div>

                 <div className='col-md-12 mt-5'>
                             
                               {postsforassignlist}

                                <div className="row">
                                    <div className="col-md-3 col-12"></div>

                                    <div className="col-md-6 col-12">


                                        <ReactPaginate
                                            // previousLabel={"previous"}
                                            // nextLabel={"next"}
                                            previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}
                                            nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            breakLinkClassName={"break-mef"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClicklist}
                                            forcePage={offsetforassignlist - 1}

                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}

                                        />


                                    </div>

                                </div>
                     </div>
                     </div>

      
            {/* <Assiglist />
         */}

      </div>

        </div>



       
       
        </>
    )


}


export default UploadNumber
