

// import '../App.css';
import { useEffect, useState} from 'react'
import { useNavigate ,Link} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Login from './Login';
// import Navbar from './Navbar';
import Swal from 'sweetalert2';
import { FiTrash } from 'react-icons/fi';
import { GiVibratingSmartphone } from 'react-icons/gi';
import { motion } from 'framer-motion';
import {LiveAgentRecord,AgentLiveAgentRecord} from './Api'
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { TbPlayerTrackPrevFilled } from "react-icons/tb";
import { AiOutlineLogout } from "react-icons/ai";



export const Realtime = (props) => {

    useEffect(() => {
        document.title = "Realtime"
     }, []);

    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/');
        }
    }, [])

   
    //  const [pagev ,setPagev] = useState("NA")

    //  useEffect(() => {
    //     const currentUrl = window.location.href;
    //     console.log(">>>>currentUrl>>>>"+currentUrl);
    
    //     // You can also access specific parts of the URL like pathname, search, etc.
    //     const pathname = window.location.pathname;
    //     const searchParams = window.location.search;
    //     console.log(">>>>>pathname---searchParams>>>>>"+pathname);
    //     setPagev(pathname)
    //     // Do something with the URL information
    //     // ...
    //   }, [pagev]);
   

     var today;

     // console.log(today);
     const [offset, setOffset] = useState(1); 
     const [intervalId, setIntervalId] = useState(null);




   useEffect(() => {
    
      const timer = setInterval( () => {

        //console.log(">>>>>>>>>"+props.commonProp)

       if(props.commonProp ==="Realtime")
       {
       // alert(">>>>")
       
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
      
           today = Math.round((new Date()).getTime() / 1000)
        
           if (localStorage.getItem('roles') === 'ROLE_ADMIN')
           {
          //console.log(today);
        //   console.log(props.commonProp);
        //       const res2 = await axios.get(process.env.REACT_APP_IP+`/User/getLiveAgentRecord?pageNumber=${offset - 1}&&size=20`,
        //       { 
        //         headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
        //      })

        LiveAgentRecord(offset)

        .then((response) => {
            const data = response.data;
             
              const postData = getPostData(data)
              setAllPosts(postData)
            //  console.log(">>>>>"+posts)
              setPageCount(Math.ceil(data.totalPages))
          })
          .catch((error) => {
            // Handle the error
            //console.log("Getting Error in LiveAgentRecord")
          });

           // const res2 = LiveAgentRecord(offset)

           // alert(res2.data)
     
             
        }  } else {
            history('/');
        }
    }else{
        return null;
    }
          
       return () => clearInterval(timer);
      }, 5000)

    


   }, [])

  
    const [postsPerPage] = useState(10);
    const [postsPerPage2] = useState(10);
    
    const [offset2, setOffset2] = useState(1);
    const [offsetagent, setOffsetagent] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [agentposts, setAgentposts] = useState([]);
    const [posts2, setAllPosts2] = useState([]);
    const [agentpageCount, setAgentpageCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageCount2, setPageCount2] = useState(0)



    // useEffect(() => {   
    //     const timera = setInterval(async () => {          
    //          today = Math.round((new Date()).getTime() / 1000)
    //          if (localStorage.getItem('roles') === 'ROLE_ADMIN')
    //          {
    //         //console.log(today);
    //             const res = await axios.get(process.env.REACT_APP_IP+`/User/getAgentLiveAgentRecord?pageNumber=${offsetagent - 1}&&size=20`,
    //             { 
    //                 headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }

    //            })
       
                
    //             const dataagent = res.data;
    //             const postDataagent = getPostDataforagent(dataagent)
    //             setAgentposts(postDataagent)               
    //             setAgentpageCount(Math.ceil(dataagent.totalPages))
    //            // console.log(">>>agentposts>>"+agentposts)
    //       }

    //       const interval = setInterval(fetchData, 5000);
            
    //     // return () => clearInterval(timera);
    //      // Clean up the interval when the component unmounts
    // return () => {
    //     clearInterval(interval);
    //   };


    //     }, 10000)     
  
    //  }, [])



     useEffect(() => {
        const fetchData = async () => {
          try {
        //     const response = await axios.get(process.env.REACT_APP_IP+`/User/getAgentLiveAgentRecord?pageNumber=${offsetagent - 1}&&size=20`,
        //     { 
        //         headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }

        //    })

                    AgentLiveAgentRecord(offsetagent)
                    .then((response) => {
                        const dataagent = response.data;
                        //  const dataagent = jsonData.data;
                const postDataagent = getPostDataforagent(dataagent)
                 setAgentposts(postDataagent)               
                 setAgentpageCount(Math.ceil(dataagent.totalPages))
                 // setData(jsonData);
                      })
                      .catch((error) => {
                        // Handle the error
                        console.log("Getting Error in LiveAgentRecord")
                      });
                        
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        // Fetch data initially
        fetchData();
    
        // Fetch data every 5 seconds
        const interval = setInterval(fetchData, 5000);
    
        // Clean up the interval when the component unmounts
        return () => {
          clearInterval(interval);
        };
      }, []);


    // const getAllPosts = async () => {
    //     const res = await axios.get(`http://localhost:8080/withouttokenhello`)
    //     const data = res.data;
    //     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    //     // For displaying Data
    //     const postData = getPostData(slice)

    //     // Using Hooks to set value
    //     setAllPosts(postData)
    //     setPageCount(Math.ceil(data.length / postsPerPage))
    // }

    const callBarge = async(e1,e2) => {
        console.log(">>>>>>>>>>>>>>>>callbarge>>>>>>>>>>>>>>>>"+e1)
        console.log(">>>>>>>>>>>>>>>>callbarge>>>>>>>>>>>>>>>>"+e2)
        var formdata = new FormData()
        formdata.append('id', e1)
        formdata.append('username', e2)
        const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallbarge", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
        
       }


       
       const callWhisper = async(e1,e2) => {
        console.log(">>>>>>>>>>>>>>>>callWhisper>>>>>>>>>>>>>>>>"+e1)
        console.log(">>>>>>>>>>>>>>>>callWhisper>>>>>>>>>>>>>>>>"+e2)
        var formdata = new FormData()
        formdata.append('id', e1)
        formdata.append('username', e2)
        const response = await fetch(process.env.REACT_APP_IP+"/User/ClicktocallWhisper", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
        
       }


       const deleteLivecall = async(e) => {
        console.log(">>>>>>>>>>>>>>>>deleteLivecall>>>>>>>>>>>>>>>>"+e)
        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/deleteLivecallByid", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
        
       }

    const[totalagent,settotalagent] = useState(0)
    const totalcallcenteragent = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountAgentCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalagent(data)
    }
      }

      useEffect(() => {
        totalcallcenteragent()
    }, [])



    const[totalcampcallcenter,settotalcampcallcenter] = useState(0)
    const totalcampcallcenterfun = async () => {  
        
        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountTotalCampaignCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalcampcallcenter(data)
    
      }
    }

      useEffect(() => {
        totalcampcallcenterfun()
    }, [])



    const[totalpendingdatacallcenter,settotalpendingdatacallcenter] = useState(0)
    const totalpendingdatacallcenterfun = async () => {  
        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountTotalPendingdataCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalpendingdatacallcenter(data)
        }
      }

      useEffect(() => {
        totalpendingdatacallcenterfun()
    }, [])


    const getPostDataforagent = (dataagent) => {

        return (
            <>                                          
                                <tbody>
                                    {dataagent.content.map(page => {

                                    
                                        return (                                    
                                           
                                            <tr key={page.id} className={`p2 ${page.status === "BUSY" ? "dashboardbusycolor" :
                                                                               (page.status === "FREE" &&  page.loginmode === "FREE") ?"bg-greenmy":
                                                                               page.status === "HANGUP" ? "bg-warning":
                                            
                                            "bg-orange"}`}>
                                                <td>{page.username} | {page.sip}</td>
                                                <td>{page.custmobile} </td>
                                                <td>   
                                                     {page.status} | {page.loginmode}
                                                     </td>

                                                     <td>{page.type}</td>

                                                     <td>  

                                                     {page.status === "BUSY" ?
                                                     <>
                                                     {Math.floor(Math.abs((new Date() - new Date(page.callanstime))/1000))}
                                                     </>
                                                     :
                                                     page.status === "HANGUP" ?
                                                     <>
                                                     {Math.floor(Math.abs((new Date() - new Date(page.updatetime))/1000))}
                                                     </>

                                                     :
                                                     <>
                                                     {Math.floor(Math.abs((new Date() - new Date(page.updatetime))/1000))}
                                                     </>
                                                   }
                                                    
                                                    
                                                     </td>
                                                    
                                                <td>{page.campaign}</td>
                                                {/* <td>{page.id}</td> */}
                                                {/* <td>

                                                            { Math.round( today - page.itime)}

                                                </td> */}
                                                {/*                                                 
                                                <td><a style={{color:'white'}}className="fa fa-whatsapp
                                                 fa-lg btn btn-success" 
                                                 href={'https://web.whatsapp.com/send?phone='+91+page.custnumber+'&text=Hii&source=&data=&app_absent=0'}
                                                  target="_blank"></a></td> */}

                                                 <td className='text-center'><button className='btn btn-primary btn-mysm text-center' onClick={(e) => callBarge(page.custmobile,page.username)} >Barge</button></td>
                                                <td className='text-center'><button className='btn btn-primary   btn-mysm' onClick={(e) => callWhisper(page.custmobile,page.username)} >Whis</button></td> 
                                                {/* <td><button className='btn btn-danger  btn-sm' onClick={(e) => deleteLivecall(page.id)}>
                                                      <FiTrash   />
                                                      </button>
                                                      
                                                      </td>  */}
                                                 
                                            </tr>
                                              

                                        );
                                  
                                    })}
                                </tbody>                                                         
            </>

        )
    }





    

    const getAllPosts2 = async () => {
        const res2 = await axios.get(process.env.REACT_APP_IP + `/User/getRecord`,
            { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data2 = res2.data;
        //const slice2 =[];
        if(offset2>1)
        {
             const slice2 = data2.slice(postsPerPage2*(offset2-1) - 1, postsPerPage2*(offset2-1) - 1 + postsPerPage2)
             const postData2 = getPostData2(slice2)

             //     // Using Hooks to set value
             setAllPosts2(postData2)
             setPageCount2(Math.ceil(data2.length / postsPerPage2))

        }else{
            const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)
            const postData2 = getPostData2(slice2)

            //     // Using Hooks to set value
            setAllPosts2(postData2)
            setPageCount2(Math.ceil(data2.length / postsPerPage2))
        }
        
       // const slice2=abslice;
       //const slice2 = data2.slice(postsPerPage2)
        // For displaying Data
       
    }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;

        console.log(">>>>>>selectedPage>>>>>>>>"+selectedPage)
        setOffset(selectedPage + 1)
    };

    const handlePageClick2 = (event) => {
        const selectedPage2 = event.selected;
        setOffset2(selectedPage2 + 1)
    };

    //  useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //      getAllPosts()
    //     }
    //  }, [offset])


    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('token')) {
                getAllPosts2()
    
            }
            //getAllPosts2(); // your function to fetch data
        }, 5000);
    
        return () => clearInterval(interval);
      }, [offset2]);



      const logoutALlAgent= async (e) => {
        // e.preventDefault();
        // var formdata = new FormData()
        // formdata.append('id', e)
         const response = await fetch(process.env.REACT_APP_IP+"/Admin/LogoutAllAgentApi", {
             method: 'POST',
             // headers: {
             //     'Content-Type': 'application/json'
             // },
 
             headers: {
                 //'Content-Type': 'application/json',
 
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
             }
            // body: formdata
             // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
         });
         const json = await response.json()
         console.log(json);
         if(json.type==="OK")
         {
            // props.showAlert(json.msg, "success");
            Swal.fire({
             position: 'top-end',
             icon: 'success',
             title: json.msg,
             background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
             showConfirmButton: false,
             timer: 2500
           })
         }else{
             //props.showAlert(json.msg, "danger");
             Swal.fire({
                 position: 'top-end',
                 icon: 'error',
                 title: json.msg,
                 background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                 showConfirmButton: false,
                 timer: 2500
               })
         }
        
         //history('/AddCampaign');
         // alert("Campaign Added");
        // setformyalert(!formyalert);
        // getAllPosts()
 
     }
    

    //  useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         getAllPosts2()

    //     }
        
    //  }, [offset2])


    const { showAlert } = props

    const getPostData2 = (data2) => {

        return (
            <>

               
                                <tbody>
                                     {data2.map(page2 => {
                                        return (
                                            <tr key={page2.id}>
                                                <td>{page2.list}</td>
                                                <td>{page2.pendingdata}</td>
                                                <td>{page2.dialeddata}</td>
                                                <td>{page2.totaldatainlist}</td>

                                            </tr>
                                        );
                                    })} 
                                </tbody>                          

            </>




        )
    }
    const getPostData = (data) => {

        return (
            <>
            

             

                {/* <h2>This is home page</h2> */}

                {/* call */}
               

                   

                    {/* <div className="col-sm-12 col-12 my-2" >
                        <h5>Last Call Report</h5>
                        <div className="table-responsive">
                        <table className="table table-hover bg-info text-white table-striped ">
                                <thead className='bg-darkblue'>
                                    <tr>
                                       
                                        <th>CustomerNumber</th>
                                        <th>Campaign</th>
                                        <th>Dialstatus</th>
                                        <th>Dialtime</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.content.map(page => {
                                        return (
                                            <tr key={page.id}>
                                                
                                                <td>{page.CustomerNumber}</td>
                                                <td>{page.campaign}</td>
                                                <td>{page.dialstatus}</td>
                                                <td>{page.dialtime}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div> */}




                        
                                
                                <tbody>
                                    {data.content.map(page => {

                                    
                                        return (

                                        //     <tr key={page.id}>
                                        //     <td>8810692314</td>
                                        //     <td>1207182964</td>
                                        //     <td>9560179221</td>
                                        //     <td>1</td>
                                        //     <td>00:00:45</td>
                                        // </tr>
                                        // Math.round( today - page.itime) === 1 ? (
                                          //  Math.round( today - page.itime) < 3600 ? (
                                            Math.round( today - page.itime) < 360000000 ? (
                                              <tr key={page.id} className={` ${page.agentnum === null ?"bg-warning":"hide"}`}>
                                              {/* <tr key={page.id}>  */}
                                                <td>{page.custnumber}</td>
                                                <td >
                                                            <div className='row '>
                                                            {/* <div className='col-md-3 col-6'>
                                                                    <i style={{color:'blue'}}className=" fa fa-phone fa-lg"></i>
                                                            </div> */}
                                                            <div className='col-md-12 col-6'>
                                                                    <div style={{ marginTop:'6px'}} className="dots" >

                                                                    <div><GiVibratingSmartphone color="blue" size="16px" /></div>
                                                                    <div className='mx-1'><GiVibratingSmartphone color="blue" size="18px" /></div>
                                                                            <div><GiVibratingSmartphone color="blue" size="20px" /></div>
                                                                                
                                                                            <div><GiVibratingSmartphone color="blue" size="22px" /></div>
                                                                           
                                                                            {/* <div><GiVibratingSmartphone color="blue" size="16px" /></div> */}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                </td>
                                               
                                                <td>{page.agentnum} | {page.username}</td>
                                                <td>{page.did} | {page.campname}</td>
                                                <td>{page.type}</td>
                                                <td>

                                                            { Math.round( today - page.itime)}

                                                </td>
{/*                                                 
                                                <td><a style={{color:'white'}}className="fa fa-whatsapp fa-lg btn btn-success" href={'https://web.whatsapp.com/send?phone='+91+page.custnumber+'&text=Hii&source=&data=&app_absent=0'} target="_blank"></a></td> */}

                                                 {/* <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Barge</button></td>
                                                <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Whis</button></td>  */}
                                                <td><button className='btn btn-danger  btn-sm' onClick={(e) => deleteLivecall(page.id)}>
                                                      <FiTrash   />
                                                      </button>
                                                      
                                                      </td> 
                                                 
                                            </tr>
                                              ):null

                                        );
                                  
                                    })}
                                </tbody>
                           
                 
               

            </>




        )
    }


    return (


        
        <div className='mt-3'>
{/* <Navbar /> */}


{
            localStorage.getItem('token') ?

            <div className="container-fluid " > 
            {/* style={{marginTop:"95px"}} */}
                

                    {/* <h1>This is Home page</h1> */}

                    <div className="row" >

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box">
                                    {/* mybg-info mybg-danger mybg-success  mybg-warning*/}
                                    <div className="inner">
                                        <h3>{totalagent}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-user"></i>
                                        </div>

                                        <p>Total Agent</p>

                                    </div>

                                    {/* <Link className="small-box-footer" to="/AddUser" >More info<i className=" mx-2 fa fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>
          
                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box  ">
                                    {/* mybg-info  mybg-success  mybg-warning*/}
                                    <div className="inner">
                                        <h3>{totalcampcallcenter}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-sitemap"></i>
                                            {/* <i className="fa-solid fa-sitemap"></i> */}
                                        </div>

                                        <p>Total Campaign</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    {/* <Link className="small-box-footer" to="/AddCampaign" >More info<i className=" mx-2 fa fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box ">
                                    {/* mybg-info    mybg-warning*/}
                                    <div className="inner">
                                        <h3>{totalpendingdatacallcenter}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-table"></i>
                                            {/* <i className="fa-solid fa-indian-rupee-sign"></i> */}
                                        </div>

                                        <p>Pending Data</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a> */}
                                </div>
                            </div>



                            <div className="col-lg-3 col-6 text-white  ">

                                <div className="small-box">
                                    <div className="inner">
                                        <h3>{totalcampcallcenter}</h3>
                                        <div className="icon">
                                            {/* <i className=" fa fa-volume-control-phone"></i> */}
                                            <i className=" fa fa-sitemap"></i>
                                        </div>

                                        <p>Today-Camp</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a> */}
                               
                                </div>
                            </div>
                       
                        
                       
                    </div>
                    {/* Display all the posts */}
                    <div className="row mb-5 mt-1" >
                    
                      
                            <div className="col-sm-8 col-12 " >
                               
                            <h5 className='text-center me-1 card-header'>Live Agent <button className='btn btn-danger btn-mysm float-end' onClick={() => logoutALlAgent()}><AiOutlineLogout className='me-2'/>All Logout</button></h5>
                                
                                <div className="table-responsive ignoreline ">
                                    {/* <motion.div    
                                        initial={{ rotate: 270 }}            
                                        animate={{ x: 0, opacity: 2.0,rotate: 360}}
                                        transition={{ duration: 0.5}}          
                                                > */}
                                    <table className="table table-hover table-bordered">
                                        <thead className='bg-darkblue text-white'>
                                            <tr>
                                                <th>Agent</th>
                                                <th>Customer</th>
                                                <th>Status</th>
                                                <th>CallType</th>
                                                <th>Duration</th>
                                                <th>Campaign</th>
                                                
                                                <th>Barge</th>
                                                <th>Whisper</th>
                                               
                                            </tr>
                                        </thead>
                                        {agentposts}
                                    </table>
                                    {/* </motion.div> */}
                                </div>
                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-6" >
                                        {/* Using React Paginate */}
                                        <ReactPaginate
                                           previousLabel={<TbPlayerTrackPrevFilled />}
                                           nextLabel={<TbPlayerTrackNextFilled />}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={agentpageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>
                                    <div className="col-sm-2" ></div>
                                </div>
                            </div>


                <div className="col-sm-4 col-12 " >

                <div className= "col-sm-12 col-12 ">
                        <h5 className='text-center card-header'>Pending Data List</h5>
                        <div className="table-responsive ignoreline">
                        {/* <motion.div    
                                        initial={{ rotate: 270 }}            
                                        animate={{ x: 0, opacity: 2.0,rotate: 360}}
                                        transition={{ duration: 0.5}}          
                                                > */}
                            <table className="table table-hover table-bordered checkoddata">
                                    <thead className='bg-darkblue text-white'>
                                            <tr>
                                                <th>ListName</th>
                                                <th>Pendingdata</th>
                                                <th>Dialeddata</th>
                                                <th>Totaldata</th>

                                            </tr>
                                    </thead>
                            {posts2}
                            </table>
                        {/* </motion.div> */}
                        </div>
                </div>


                    {/* Using React Paginate */}

                    <div className="row ">
                            <div className="col-sm-2" >

                            </div>
                            <div className="col-sm-8" >
                            <ReactPaginate
                               previousLabel={<TbPlayerTrackPrevFilled />}
                               nextLabel={<TbPlayerTrackNextFilled />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount2}
                                onPageChange={handlePageClick2}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination2"}
                                activeClassName={"active"} />
                            </div>

                            <div className="col-sm-2" >

                            </div>

                        </div>


                </div>


                <div className="col-sm-12 col-12 " >
                                <h5 className='text-center'>Calls In Queue </h5>
                                <div className="table-responsive ignoreline  ">
                                    {/* <motion.div    
                                        initial={{ rotate: 270 }}            
                                        animate={{ x: 0, opacity: 2.0,rotate: 360}}
                                        transition={{ duration: 0.5}}          
                                                > */}
                                    <table className="table table-hover table-bordered">
                                        <thead className='bg-darkblue text-white'>
                                            <tr>
                                                <th>Caller</th>
                                                <th></th>
                                                <th>Agent</th>
                                                <th>Campaign</th>
                                                <th>Type</th>
                                                <th>Duration</th>
                                               
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {posts}
                                    </table>
                                    {/* </motion.div> */}
                                </div>
                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-6" >
                                        {/* Using React Paginate */}
                                        <ReactPaginate
                                            previousLabel={<TbPlayerTrackPrevFilled />}
                                            nextLabel={<TbPlayerTrackNextFilled />}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>
                                    <div className="col-sm-2" ></div>
                                </div>
                            </div>






                </div>


            </div>:<div>

                <Login />
            </div>

}

        </div> 

    



    )



}
export default Realtime

