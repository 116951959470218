import {React, useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { motion } from 'framer-motion';

const Smssuits = () => {

    useEffect(() => {
        document.title = "Smssuits"
     }, []);

    const [smscampname, setSmscampname] = useState();
    const [sms, setSms] = useState();
    const [number, setNumber] = useState();

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    
    const smsCampaignSubmit = async(e) => {

        e.preventDefault();
        if (smscampname === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Error",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        if (sms === '' || sms ==='undefined') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Sms Blank",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        if (number === '' || number ==='undefined') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Number Blank",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
       // alert("smscampname>>>>"+smscampname+"<<<<<<<sms>>>>>>"+sms+"<<<<<<number>>>>>>"+number)
       //alert("<<<<<<<sms>>>>>>"+sms)
       var formdata = new FormData()
       formdata.append('smscampname', smscampname)
       formdata.append('sms', sms)
       formdata.append('number', number)
       const response = await fetch(process.env.REACT_APP_IP + "/User/AddSmsCampaign", {
           method: 'POST',
           headers: {
               // 'Content-Type': 'application/json',

               "Authorization": `Bearer ${localStorage.getItem('token')}`
           },
           body: formdata

           // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
       });
       const json = await response.json()
       console.log(json);
       if (json.type === "OK") {
           // props.showAlert(json.msg, "success");
           Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: json.msg,
               background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
               showConfirmButton: false,
               timer: 2500
           })
       } else {
           //props.showAlert(json.msg, "danger");
           Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: json.msg,
               background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
               showConfirmButton: false,
               timer: 2500
           })
        }

        setNumber("")
        setSmscampname("")
        setSms("")
        getAllPostsAlldata()
        getAllPostsAlldatasmscount()
      
    }

    const [offset, setOffset] = useState(1);
    const [Offsetsms, setOffsetsms] = useState(1);
    
    const [posts, setAllPosts] = useState([]);
    const [postssmscount, setAllPostssmscount] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCountsms, setpageCountsms] = useState(0)
    


    const getAllPostsAlldata = async () => {
        // setOffset(1)
         const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllSmsCampaign?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
         const data = res.data;
         const postData = getPostData(data)
         // Using Hooks to set value
         setAllPosts(postData)
         // setPageCount(Math.ceil(data.length / postsPerPage))
         setPageCount(Math.ceil(data.totalPages))
         
     }
     const [postsPerPage2] = useState(10);

     const getAllPostsAlldatasmscount = async () => {
        // setOffset(1)
         const res = await axios.get(process.env.REACT_APP_IP + `/Sms/SmsCountAction`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
         const datasms = res.data;

           if(Offsetsms>1)
            {
                 const slice2 = datasms.slice(postsPerPage2*(Offsetsms-1) - 1, postsPerPage2*(Offsetsms-1) - 1 + postsPerPage2)
                 const postData2 = getPostDatasmscount(slice2)
    
                 //     // Using Hooks to set value
                 setAllPostssmscount(postData2)
                 setpageCountsms(Math.ceil(datasms.length / postsPerPage2))
    
            }else{
                const slice2 = datasms.slice(Offsetsms - 1, Offsetsms - 1 + postsPerPage2)
                const postData2 = getPostDatasmscount(slice2)
    
                //     // Using Hooks to set value
                setAllPostssmscount(postData2)
                setpageCountsms(Math.ceil(datasms.length / postsPerPage2))
            }

         
         
     }

     const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handlePageClicksms = (event) => {
        const selectedPage = event.selected;
        setOffsetsms(selectedPage + 1)
    };

    

     useEffect(() => {
        getAllPostsAlldata()
    }, [offset])

    useEffect(() => {
        getAllPostsAlldatasmscount()
    }, [Offsetsms])
 

    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
       // const id = e
        var formdata = new FormData()
       //formdata.append('smscampname', smscampname)
      // formdata.append('sms', sms)
       formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/StartStopSmsCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
               // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        //props.showAlert("Done Successfully ", "success");
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
         }
        getAllPostsAlldata()
        setIsToggled(!isToggled);


    }
    

    const getPostData = (data) => {
        return (
            <>

                



                               
                                            <tbody>
                                                {data.content.map(page => {
                                                    return (
                                                        <tr key={page.id} className='text-left'>
                                                            <td>{page.id}</td>
                                                            <td>{page.campname}</td>
                                                            <td>{page.campstatus}</td>
                                                            <td>
                                                                    <label className="toggle-switch">
                                                                        <input type="checkbox" checked={page.campstatus === "START"} onChange={() => onToggle(page.id)} />
                                                                        <span className="switch" />
                                                                    </label>

                                                            </td>
                                                           
                                                            <td>{page.sms}</td>
                                                            <td>{page.starttime}</td>
                                                            <td>{page.endtime}</td>
                                                           
                                                           

                                                            <td>{page.insertdate}</td>
                                                            <td>{page.modifieddate}</td>
                                                           


                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                      
                                        {/* Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} /> */}
                                  

                            


            </>

        )

    }


    const getPostDatasmscount = (datasmscount) => {
        return (
            <>

                



                               
                                            <tbody>

                                                


                                                    {datasmscount.map((row, index) => {
                                                    return (
                                                        <tr key={index} className='text-left'>
                                                            <td>{row[0]}</td>
                                                            <td>{row[1]}</td>
                                                            <td>{row[2]}</td>
                                                            
                                                            <td>{row[4]}</td>
                                                            <td>{row[3]}</td>
                                                            
                                                            <td>{row[4] + row[2] - row[3]}</td>
                                                            {/* <td>0</td> */}


                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                      
                                        {/* Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} /> */}
                                  

                            


            </>

        )

    }



  return (


    <>
   
           <div className='row'>
                    <div className='col-md-3'>
                    <form className='card mybgcolor p-1' style={{  marginTop: "1px"
                }}
                 onSubmit={smsCampaignSubmit}
                 >
                    <h4 className='card-header text-center'>Add SMS Campaign</h4>
                    <div className="mb-1">
                        <label>Name</label>
                        <input name="agentname" type="text" className=" form-control form-control-sm"
                            value={smscampname}
                            onChange={(e) => setSmscampname(e.target.value)}
                             required />

                        
                    </div>

                    <div className="mb-1">
                        <label>SMS</label>
                        <textarea className="form-control"
                                                                    value={sms}        // Value comes from React state
                                                                    // onChange={handleChange}  // Change event to update state
                                                                    onChange={(e) => setSms(e.target.value)}
                                                                    rows="2"
                                                                    cols="20"


                                                                />

                        
                    </div>

                    <div className="mb-1">
                        <label>Number</label>
                        <textarea className="form-control"
                                                                    value={number}        // Value comes from React state
                                                                    // onChange={handleChange}  // Change event to update state
                                                                    onChange={(e) => setNumber(e.target.value)}
                                                                    rows="10"
                                                                    cols="40"


                                                                />

                        
                    </div>               
                             <input className="btn btn-primary mt-3" type="submit" value="Submit" />           
                    
                </form>

                    </div>
                    <div className='col-md-9'>
                    <div className="mt-1">
                            <motion.div
                            initial={{ x: -400 }} // Starting position (left side)
                            animate={{ x: 0 }} // Ending position (300px to the right)
                            transition={{ duration: 0.5 }} // Duration of 2 seconds
                            
                            >
                                 <h4 className='card-header text-center'>Existing Campaign</h4>
                                   
                                   <table className="table table-hover table-bordered checkoddata ignoreline">

                                       <thead className="bg-darkblue text-white nowrap">
                                           
                                           <tr>
                                               <th>ID</th>
                                               <th>Campaign</th>

                                               <th>Status</th>
                                               <th></th>
                                               <th>SMS</th>
                                               <th>StartTime</th>
                                               <th>EndTime</th>
                                               <th>Date</th>
                                               <th>ChangeDate</th>                                              

                                           </tr>
                                       </thead>
                                        {posts}                                   
                                     </table>
                    </motion.div>
                    
                  
                   
                     
                   <div className='row mb-5'>
                        <div className='col-md-2'>
                        
                       <input type="text" className=" form-control" id="gotopage" aria-describedby="gotopage"
                                            placeholder="gotopage" onChange={onInputChange} /> 
                   
                        </div>
                        <div className='col-md-2'></div>
                        <div className='col-md-4'>
                            <ReactPaginate
                                nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                                previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}

                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination2"}
                                activeClassName={"active"} />

                        </div>
                        <div className='col-md-4'></div>
                    </div>

                    <motion.div
                            initial={{ x: 400 }} // Starting position (left side)
                            animate={{ x: 0 }} // Ending position (300px to the right)
                            transition={{ duration: 0.5 }} // Duration of 2 seconds
                            
                            >
                                <h4 className='card-header text-center'>Existing Campaign Data</h4>
                      <table className="table table-hover table-bordered checkoddata ignoreline">
                          <thead className="bg-darkblue text-white nowrap">
                              <tr>
                                  <th>CampaignId</th>
                                  <th>Campaign</th>
                                  <th>Pending</th>
                                  <th>Sent</th>
                                  <th>Success</th>
                                  <th>Fail</th>
                                  {/* <th>Count</th> */}

                              </tr>
                          </thead>
                          {postssmscount}
                      </table>
                      </motion.div>
                      <div className='row'>
                      <div className='col-md-4'>
                        </div>
                      <div className='col-md-4'>
                            <ReactPaginate
                                nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                                previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}

                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCountsms}
                                onPageChange={handlePageClicksms}
                                forcePage={Offsetsms - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination2"}
                                activeClassName={"active"} />

                        </div>
                        </div>
                   </div>
                    </div>
                    


           </div> 
   </>
  )
}

export default Smssuits